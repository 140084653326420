import React from "react"

export const Card = React.forwardRef(({ children, style, ...props }, ref) => {
    const baseStyles = {
        borderRadius: "0.5rem",
        backgroundColor: "rgba(17, 24, 39, 0.5)",
        border: "1px solid #1f2937",
        overflow: "hidden",
    }

    const combinedStyles = {
        ...baseStyles,
        ...style,
    }

    return (
        <div ref={ref} style={combinedStyles} {...props}>
            {children}
        </div>
    )
})

Card.displayName = "Card"



export const CardHeader = React.forwardRef(({ children, style, ...props }, ref) => {
    const baseStyles = {
        padding: "1.5rem 1.5rem 0 1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
    }

    const combinedStyles = {
        ...baseStyles,
        ...style,
    }

    return (
        <div ref={ref} style={combinedStyles} {...props}>
            {children}
        </div>
    )
})

CardHeader.displayName = "CardHeader"

export const CardTitle = React.forwardRef(({ children, style, ...props }, ref) => {
    const baseStyles = {
        fontSize: "1.25rem",
        fontWeight: 600,
        lineHeight: 1.2,
        margin: 0,
        color: "#e5e7eb",
    }

    const combinedStyles = {
        ...baseStyles,
        ...style,
    }

    return (
        <h3 ref={ref} style={combinedStyles} {...props}>
            {children}
        </h3>
    )
})

CardTitle.displayName = "CardTitle"


export const CardDescription = React.forwardRef(
    ({ children, style, ...props }, ref) => {
        const baseStyles = {
            fontSize: "0.875rem",
            color: "#9ca3af",
            margin: 0,
        }

        const combinedStyles = {
            ...baseStyles,
            ...style,
        }

        return (
            <p ref={ref} style={combinedStyles} {...props}>
                {children}
            </p>
        )
    },
)

CardDescription.displayName = "CardDescription"


export const CardContent = React.forwardRef(({ children, style, ...props }, ref) => {
    const baseStyles = {
        padding: "1.5rem",
    }

    const combinedStyles = {
        ...baseStyles,
        ...style,
    }

    return (
        <div ref={ref} style={combinedStyles} {...props}>
            {children}
        </div>
    )
})

CardContent.displayName = "CardContent"

export const CardFooter = React.forwardRef(({ children, style, ...props }, ref) => {
    const baseStyles = {
        padding: "0 1.5rem 1.5rem 1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "0.5rem",
    }

    const combinedStyles = {
        ...baseStyles,
        ...style,
    }

    return (
        <div ref={ref} style={combinedStyles} {...props}>
            {children}
        </div>
    )
})

CardFooter.displayName = "CardFooter"


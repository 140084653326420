"use client"

import { useState } from "react"
import { motion } from "framer-motion"
import { Plus, Wallet, Edit2, Trash2, Check } from "lucide-react"
import { Button } from "../../ui/button"
import { Textarea } from "../../ui/textarea"
import { Input } from "../../ui/input"
import { Label } from "../../ui/label"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../ui/card"

export default function CatalogPage() {
  const [selectedProduct, setSelectedProduct] = useState("video")
  const [selectedOptions, setSelectedOptions] = useState({})
  const [description, setDescription] = useState("")
  const [thumbnail, setThumbnail] = useState(null)
  const [preview, setPreview] = useState(null)
  const [windowWidth, setWindowWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 1024)

  // Add window resize listener for responsive styling
  if (typeof window !== "undefined") {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
  }

  const products = [
    { id: "video", label: "VIDEO", color: "blue-cyan", gradient: "linear-gradient(to bottom right, #3b82f6, #06b6d4)" },
    {
      id: "audio",
      label: "AUDIO",
      color: "purple-pink",
      gradient: "linear-gradient(to bottom right, #a855f7, #ec4899)",
    },
    { id: "nft", label: "NFT", color: "green-emerald", gradient: "linear-gradient(to bottom right, #10b981, #059669)" },
    {
      id: "tickets",
      label: "TICKETS",
      color: "yellow-orange",
      gradient: "linear-gradient(to bottom right, #facc15, #f97316)",
    },
    {
      id: "message",
      label: "MESSAGE",
      color: "red-rose",
      gradient: "linear-gradient(to bottom right, #ef4444, #f43f5e)",
    },
    {
      id: "subs",
      label: "SUBS",
      color: "indigo-violet",
      gradient: "linear-gradient(to bottom right, #6366f1, #8b5cf6)",
    },
  ]

  const durations = [
    { mins: "5", price: "50" },
    { mins: "10", price: "75" },
    { mins: "15", price: "85" },
    { mins: "30", price: "100" },
    { mins: "60", price: "150" },
    { mins: "120", price: "200" },
  ]

  const messageTypes = [
    { id: "demo", label: "Demo Feedback", price: "50" },
    { id: "career", label: "Career Advice", price: "100" },
    { id: "production", label: "Production Advice", price: "75" },
  ]

  const handleOptionSelect = (option, isSelected) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [selectedProduct]: {
        ...prev[selectedProduct],
        [option.id || option.mins]: isSelected ? option : undefined,
      },
    }))
  }

  const handlePriceChange = (option, newPrice) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [selectedProduct]: {
        ...prev[selectedProduct],
        [option.id || option.mins]: { ...option, price: newPrice },
      },
    }))
  }

  const isOptionSelected = (option) => {
    return !!selectedOptions[selectedProduct]?.[option.id || option.mins]
  }

  const getProductColorClass = (productId) => {
    const product = products.find((p) => p.id === productId)
    return product ? product.color : ""
  }

  const getProductBorderColor = (productId) => {
    switch (productId) {
      case "video":
        return "#0ea5e9"
      case "audio":
        return "#a855f7"
      case "nft":
        return "#10b981"
      case "tickets":
        return "#f59e0b"
      case "message":
        return "#ef4444"
      case "subs":
        return "#6366f1"
      default:
        return "#1f2937"
    }
  }

  const getProductTextColor = (productId) => {
    switch (productId) {
      case "video":
        return "#22d3ee"
      case "audio":
        return "#8b5cf6"
      case "nft":
        return "#10b981"
      case "tickets":
        return "#facc15"
      case "message":
        return "#f43f5e"
      case "subs":
        return "#8b5cf6"
      default:
        return "#e5e7eb"
    }
  }

  const getProductShadow = (productId) => {
    switch (productId) {
      case "video":
        return "0 0 15px rgba(14, 165, 233, 0.2)"
      case "audio":
        return "0 0 15px rgba(168, 85, 247, 0.2)"
      case "nft":
        return "0 0 15px rgba(16, 185, 129, 0.2)"
      case "tickets":
        return "0 0 15px rgba(245, 158, 11, 0.2)"
      case "message":
        return "0 0 15px rgba(239, 68, 68, 0.2)"
      case "subs":
        return "0 0 15px rgba(99, 102, 241, 0.2)"
      default:
        return "none"
    }
  }

  // Base styles
  const styles = {
    catalogContainer: {
      minHeight: "100vh",
    /*   background: "linear-gradient(to bottom right, #030712, #0f172a, #030712)", */
      color: "white",
      padding: "1.5rem",
    },
    catalogContent: {
      maxWidth: "72rem",
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      gap: "3rem",
    },
    catalogHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    catalogTitle: {
      fontSize: "1.875rem",
      fontWeight: 700,
      background: "linear-gradient(to right, #22d3ee, #3b82f6)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      color: "transparent",
    },
    catalogSection: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    sectionHeading: {
      fontSize: "1.25rem",
      fontWeight: 600,
      color: "#d1d5db",
      marginBottom: "1rem",
    },
    productsGrid: {
      display: "grid",
      gridTemplateColumns:
        windowWidth >= 1024 ? "repeat(6, 1fr)" : windowWidth >= 768 ? "repeat(3, 1fr)" : "repeat(2, 1fr)",
      gap: "1rem",
    },
    productButton: (isSelected, productId) => ({
      position: "relative",
      padding: "1rem",
      borderRadius: "0.5rem",
      backgroundColor: "rgba(17, 24, 39, 0.5)",
      backdropFilter: "blur(8px)",
      border: isSelected ? `2px solid ${getProductBorderColor(productId)}` : "1px solid #1f2937",
      boxShadow: isSelected ? getProductShadow(productId) : "none",
      transition: "all 0.2s",
    }),
    productBackground: (productId) => ({
      position: "absolute",
      inset: 0,
      opacity: 0.1,
      borderRadius: "0.5rem",
      background: products.find((p) => p.id === productId)?.gradient || "none",
    }),
    productLabel: {
      position: "relative",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    darkCard: {
      backgroundColor: "rgba(17, 24, 39, 0.5)",
      backdropFilter: "blur(8px)",
      border: "1px solid #1f2937",
    },
    contentSpacing: {
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
    },
    durationGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "1rem",
    },
    optionButton: (isSelected, color) => ({
      width: "100%",
      padding: "0.75rem",
      borderRadius: "0.5rem",
      textAlign: "left",
      transition: "all 0.2s",
      position: "relative",
      backgroundColor: isSelected
        ? `rgba${color === "cyan" ? "(34, 211, 238, 0.2)" : color === "green" ? "(16, 185, 129, 0.2)" : "(244, 63, 94, 0.2)"}`
        : "rgba(31, 41, 55, 0.5)",
      border: isSelected
        ? `2px solid ${color === "cyan" ? "#22d3ee" : color === "green" ? "#10b981" : "#f43f5e"}`
        : "1px solid #374151",
    }),
    checkIcon: {
      position: "absolute",
      top: "0.5rem",
      right: "0.5rem",
    },
    lightText: {
      color: "#e5e7eb",
    },
    priceInput: {
      marginTop: "0.25rem",
      width: "5rem",
      backgroundColor: "#374151",
      borderColor: "#4b5563",
      color: "#e5e7eb",
    },
    marketplaceGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "1rem",
    },
    walletButton: {
      width: "100%",
      background: "linear-gradient(to right, #10b981, #059669)",
    },
    messageGrid: {
      display: "grid",
      gap: "1rem",
    },
    messageContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerWithButton: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sectionTitle: {
      fontSize: "1.125rem",
      fontWeight: 500,
      color: "#e5e7eb",
    },
    tierContainer: {
      padding: "1rem",
      borderRadius: "0.5rem",
      backgroundColor: "rgba(31, 41, 55, 0.5)",
      border: "1px solid #374151",
    },
    tierContent: {
      display: "grid",
      gap: "1rem",
    },
    tierHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    benefitsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    benefitItem: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    },
    darkInput: {
      backgroundColor: "#374151",
      borderColor: "#4b5563",
      color: "#e5e7eb",
    },
    tierNameInput: {
      maxWidth: "200px",
      backgroundColor: "#374151",
      borderColor: "#4b5563",
      color: "#e5e7eb",
    },
    tierPriceInput: {
      maxWidth: "100px",
      backgroundColor: "#374151",
      borderColor: "#4b5563",
      color: "#e5e7eb",
    },
    descriptionTextarea: {
      minHeight: "150px",
      backgroundColor: "rgba(17, 24, 39, 0.5)",
      borderColor: "#1f2937",
      color: "#e5e7eb",
    },
    uploadGrid: {
      display: "grid",
      gridTemplateColumns: windowWidth >= 640 ? "repeat(2, 1fr)" : "1fr",
      gap: "1rem",
    },
    uploadLabel: {
      display: "block",
      marginBottom: "0.5rem",
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#d1d5db",
    },
    uploadContainer: {
      marginTop: "0.25rem",
      display: "flex",
      justifyContent: "center",
      padding: "1.5rem",
      border: "2px dashed #4b5563",
      borderRadius: "0.375rem",
    },
    uploadContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "0.25rem",
    },
    uploadIcon: {
      width: "3rem",
      height: "3rem",
      color: "#9ca3af",
      margin: "0 auto",
    },
    uploadText: {
      display: "flex",
      fontSize: "0.875rem",
      color: "#9ca3af",
    },
    uploadButton: {
      position: "relative",
      cursor: "pointer",
      backgroundColor: "#111827",
      borderRadius: "0.375rem",
      fontWeight: 500,
      color: "#22d3ee",
    },
    uploadOr: {
      paddingLeft: "0.25rem",
    },
    uploadHint: {
      fontSize: "0.75rem",
      color: "#6b7280",
    },
    addCatalogButton: {
      width: "100%",
      background: "linear-gradient(to right, #22d3ee, #3b82f6)",
    },
    summarySection: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    productTitle: {
      fontSize: "1.125rem",
      fontWeight: 500,
      color: "#e5e7eb",
    },
    summaryItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0.5rem",
      borderRadius: "0.375rem",
      backgroundColor: "rgba(31, 41, 55, 0.5)",
    },
    summaryActions: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    },
    summaryIconButton: {
      height: "2rem",
      width: "2rem",
    },
    cyanText: {
      color: "#22d3ee",
    },
    lightGrayText: {
      color: "#9ca3af",
    },
    yellowText: {
      color: "#facc15",
    },
    greenText: {
      color: "#10b981",
    },
    roseText: {
      color: "#f43f5e",
    },
    violetText: {
      color: "#8b5cf6",
    },
    publishButton: {
      width: "100%",
      background: "linear-gradient(to right, #10b981, #059669)",
      fontSize: "1.125rem",
      padding: "1.5rem 0",
    },
    iconLeft: {
      marginRight: "0.5rem",
      height: "1rem",
      width: "1rem",
    },
    smallIcon: {
      height: "1rem",
      width: "1rem",
    },
    srOnly: {
      position: "absolute",
      width: "1px",
      height: "1px",
      padding: 0,
      margin: "-1px",
      overflow: "hidden",
      clip: "rect(0, 0, 0, 0)",
      whiteSpace: "nowrap",
      borderWidth: 0,
    },
    outlineButton: (color) => ({
      borderColor: color,
      color: color,
    }),
  }

  const renderStep2Content = () => {
    switch (selectedProduct) {
      case "video":
      case "audio":
        return (
          <Card style={styles.darkCard}>
            <CardHeader>
              <CardTitle style={styles.cyanText}>Time Duration</CardTitle>
              <CardDescription style={styles.lightGrayText}>Select the duration for your content</CardDescription>
            </CardHeader>
            <CardContent>
              <div style={styles.durationGrid}>
                {durations.map((duration) => (
                  <div key={duration.mins} style={{ position: "relative" }}>
                    <button
                      onClick={() => handleOptionSelect(duration, !isOptionSelected(duration))}
                      style={styles.optionButton(isOptionSelected(duration), "cyan")}
                    >
                      <div style={styles.lightText}>{duration.mins} mins</div>
                      <Input
                        type="text"
                        value={selectedOptions[selectedProduct]?.[duration.mins]?.price || duration.price}
                        onChange={(e) => handlePriceChange(duration, e.target.value)}
                        style={styles.priceInput}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </button>
                    {isOptionSelected(duration) && <Check style={{ ...styles.checkIcon, color: "#22d3ee" }} />}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )

      case "tickets":
        return (
          <Card style={styles.darkCard}>
            <CardHeader>
              <CardTitle style={styles.yellowText}>Ticket Configuration</CardTitle>
              <CardDescription style={styles.lightGrayText}>Set up your ticket tiers and quantities</CardDescription>
            </CardHeader>
            <CardContent style={styles.contentSpacing}>
              <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <div style={styles.headerWithButton}>
                  <h3 style={styles.sectionTitle}>Ticket Tiers</h3>
                  <Button variant="outline" style={styles.outlineButton("#facc15")}>
                    <Plus style={styles.iconLeft} /> Add Tier
                  </Button>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                  <div style={styles.tierContainer}>
                    <div style={{ display: "grid", gap: "1rem" }}>
                      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "1rem" }}>
                        <Input placeholder="Tier Name" style={styles.darkInput} />
                        <Input type="text" placeholder="Price" style={styles.darkInput} />
                        <Input type="text" placeholder="Quantity" style={styles.darkInput} />
                      </div>
                      <Input placeholder="Description" style={styles.darkInput} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  backgroundColor: "rgba(31, 41, 55, 0.5)",
                  border: "1px solid #374151",
                }}
              >
                <span style={styles.lightGrayText}>Total Tickets</span>
                <span style={{ color: "#facc15", fontWeight: "bold" }}>0</span>
              </div>
            </CardContent>
          </Card>
        )

      case "nft":
        return (
          <Card style={styles.darkCard}>
            <CardHeader>
              <CardTitle style={styles.greenText}>NFT Marketplace Connection</CardTitle>
              <CardDescription style={styles.lightGrayText}>
                Connect your wallet and choose a marketplace
              </CardDescription>
            </CardHeader>
            <CardContent style={styles.contentSpacing}>
              <Button style={styles.walletButton}>
                <Wallet style={styles.iconLeft} /> Connect Wallet
              </Button>
              <div style={styles.marketplaceGrid}>
                {["OpenSea", "Rarible", "Foundation", "SuperRare"].map((marketplace) => (
                  <button
                    key={marketplace}
                    onClick={() => handleOptionSelect({ id: marketplace }, !isOptionSelected({ id: marketplace }))}
                    style={styles.optionButton(isOptionSelected({ id: marketplace }), "green")}
                  >
                    <span style={styles.lightText}>{marketplace}</span>
                    {isOptionSelected({ id: marketplace }) && (
                      <Check style={{ ...styles.checkIcon, color: "#10b981" }} />
                    )}
                  </button>
                ))}
              </div>
            </CardContent>
          </Card>
        )

      case "message":
        return (
          <Card style={styles.darkCard}>
            <CardHeader>
              <CardTitle style={styles.roseText}>Message Type</CardTitle>
              <CardDescription style={styles.lightGrayText}>Select the type of consultation</CardDescription>
            </CardHeader>
            <CardContent>
              <div style={styles.messageGrid}>
                {messageTypes.map((type) => (
                  <div key={type.id} style={{ position: "relative" }}>
                    <button
                      onClick={() => handleOptionSelect(type, !isOptionSelected(type))}
                      style={styles.optionButton(isOptionSelected(type), "rose")}
                    >
                      <div style={styles.messageContent}>
                        <span style={styles.lightText}>{type.label}</span>
                        <Input
                          type="text"
                          value={selectedOptions[selectedProduct]?.[type.id]?.price || type.price}
                          onChange={(e) => handlePriceChange(type, e.target.value)}
                          style={styles.priceInput}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </button>
                    {isOptionSelected(type) && <Check style={{ ...styles.checkIcon, color: "#f43f5e" }} />}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )

      case "subs":
        return (
          <Card style={styles.darkCard}>
            <CardHeader>
              <CardTitle style={styles.violetText}>Subscription Tiers</CardTitle>
              <CardDescription style={styles.lightGrayText}>
                Configure your subscription tiers and benefits
              </CardDescription>
            </CardHeader>
            <CardContent style={styles.contentSpacing}>
              <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <div style={styles.headerWithButton}>
                  <h3 style={styles.sectionTitle}>Membership Tiers</h3>
                  <Button variant="outline" style={styles.outlineButton("#8b5cf6")}>
                    <Plus style={styles.iconLeft} /> Add Tier
                  </Button>
                </div>
                {[
                  { name: "Bronze", price: "5", benefits: ["Exclusive posts", "Behind the scenes"] },
                  { name: "Silver", price: "15", benefits: ["Bronze benefits", "Priority access", "Discord role"] },
                  { name: "Gold", price: "30", benefits: ["Silver benefits", "Monthly meetup", "Custom badge"] },
                ].map((tier, index) => (
                  <div key={index} style={styles.tierContainer}>
                    <div style={styles.tierContent}>
                      <div style={styles.tierHeader}>
                        <Input value={tier.name} style={styles.tierNameInput} />
                        <Input type="text" value={tier.price} style={styles.tierPriceInput} />
                      </div>
                      <div style={styles.benefitsContainer}>
                        {tier.benefits.map((benefit, benefitIndex) => (
                          <div key={benefitIndex} style={styles.benefitItem}>
                            <Input value={benefit} style={styles.darkInput} />
                          </div>
                        ))}
                        <Button
                          variant="outline"
                          size="sm"
                          style={{
                            width: "100%",
                            borderColor: "rgba(139, 92, 246, 0.5)",
                            color: "#8b5cf6",
                          }}
                        >
                          <Plus style={styles.iconLeft} /> Add Benefit
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )

      default:
        return null
    }
  }

  const renderSummary = () => {
    return (
      <Card style={styles.darkCard}>
        <CardHeader>
          <CardTitle style={styles.cyanText}>Catalog Summary</CardTitle>
          <CardDescription style={styles.lightGrayText}>Review and edit your selections</CardDescription>
        </CardHeader>
        <CardContent style={styles.contentSpacing}>
          {Object.entries(selectedOptions).map(([product, options]) => (
            <div key={product} style={styles.summarySection}>
              <h3 style={styles.productTitle}>{product.toUpperCase()}</h3>
              {Object.entries(options).map(([key, option]) => (
                <div key={key} style={styles.summaryItem}>
                  <span style={styles.lightGrayText}>{option.label || `${option.mins} mins`}</span>
                  <div style={styles.summaryActions}>
                    <span style={styles.cyanText}>${option.price}</span>
                    <Button size="icon" variant="ghost" style={{ ...styles.summaryIconButton, color: "#9ca3af" }}>
                      <Edit2 style={styles.smallIcon} />
                    </Button>
                    <Button size="icon" variant="ghost" style={{ ...styles.summaryIconButton, color: "#9ca3af" }}>
                      <Trash2 style={styles.smallIcon} />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </CardContent>
      </Card>
    )
  }

  return (
    <div style={styles.catalogContainer}>
      <div style={styles.catalogContent}>
        <div style={styles.catalogHeader}>
          <h1 style={styles.catalogTitle}>YOUR CATALOG</h1>
          <Button variant="outline" style={styles.outlineButton("#22d3ee")}>
            CREATE / EDIT CATALOG
          </Button>
        </div>

        <section style={styles.catalogSection}>
          <h2 style={styles.sectionHeading}>1. CHOOSE YOUR PRODUCTS OR SERVICES</h2>
          <div style={styles.productsGrid}>
            {products.map((product) => (
              <motion.button
                key={product.id}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedProduct(product.id)}
                style={styles.productButton(selectedProduct === product.id, product.id)}
              >
                <div style={styles.productBackground(product.id)}></div>
                <span style={styles.productLabel}>{product.label}</span>
              </motion.button>
            ))}
          </div>
        </section>

        <section style={styles.catalogSection}>
          <h2 style={styles.sectionHeading}>2. SELECT OPTIONS</h2>
          {renderStep2Content()}
        </section>

        <section style={styles.catalogSection}>
          <h2 style={styles.sectionHeading}>3. ADD DESCRIPTION AND PROMOTIONAL MATERIAL</h2>
          <Card style={styles.darkCard}>
            <CardHeader>
              <CardTitle style={styles.cyanText}>Content Details</CardTitle>
              <CardDescription style={styles.lightGrayText}>
                Describe your content and add promotional materials
              </CardDescription>
            </CardHeader>
            <CardContent style={styles.contentSpacing}>
              <Textarea
                placeholder="Add your description here..."
                style={styles.descriptionTextarea}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <div style={styles.uploadGrid}>
                <div>
                  <Label htmlFor="thumbnail-upload" style={styles.uploadLabel}>
                    Thumbnail
                  </Label>
                  <div style={styles.uploadContainer}>
                    <div style={styles.uploadContent}>
                      <svg
                        style={styles.uploadIcon}
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div style={styles.uploadText}>
                        <label htmlFor="thumbnail-upload" style={styles.uploadButton}>
                          <span>Upload a file</span>
                          <input
                            id="thumbnail-upload"
                            name="thumbnail-upload"
                            type="file"
                            style={styles.srOnly}
                            onChange={(e) => setThumbnail(e.target.files[0])}
                          />
                        </label>
                        <p style={styles.uploadOr}>or drag and drop</p>
                      </div>
                      <p style={styles.uploadHint}>PNG, JPG, GIF up to 10MB</p>
                    </div>
                  </div>
                </div>
                <div>
                  <Label htmlFor="preview-upload" style={styles.uploadLabel}>
                    Preview
                  </Label>
                  <div style={styles.uploadContainer}>
                    <div style={styles.uploadContent}>
                      <svg
                        style={styles.uploadIcon}
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div style={styles.uploadText}>
                        <label htmlFor="preview-upload" style={styles.uploadButton}>
                          <span>Upload a file</span>
                          <input
                            id="preview-upload"
                            name="preview-upload"
                            type="file"
                            style={styles.srOnly}
                            onChange={(e) => setPreview(e.target.files[0])}
                          />
                        </label>
                        <p style={styles.uploadOr}>or drag and drop</p>
                      </div>
                      <p style={styles.uploadHint}>PNG, JPG, GIF up to 10MB</p>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
            <CardFooter>
              <Button style={styles.addCatalogButton}>
                <Plus style={styles.iconLeft} /> Add to Catalog
              </Button>
            </CardFooter>
          </Card>
        </section>

        <section style={styles.catalogSection}>
          <h2 style={styles.sectionHeading}>4. REVIEW AND PUBLISH</h2>
          {renderSummary()}
          <Button style={styles.publishButton}>Publish Catalog</Button>
        </section>
      </div>
    </div>
  )
}


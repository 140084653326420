import { useState, useRef, useEffect } from "react"

import './styles.css'

export function MobileContactForm({ isOpen, onClose, fields, title, description, parentRef }) {
  const [formData, setFormData] = useState({})
  const dialogRef = useRef(null)
  const [modalStyle, setModalStyle] = useState({});

  useEffect(() => {
    if (isOpen && parentRef?.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setModalStyle({
        position: "absolute",
        top: rect.top + 15 + "px",
        left: rect.left + 15 + "px",
        width: "280px",
        maxHeight: "600px",
      });
    }
  }, [isOpen, parentRef]);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal()
    } else {
      dialogRef.current?.close()
    }
  }, [isOpen])

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("Form submitted:", formData)
    onClose()
    setFormData({})
  }

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  return (
    <dialog ref={dialogRef} className="preview-mobile-contact-dialog" style={modalStyle}>
      <div className="add-contact-form-mobile-frame mobile-frame">
          <h4>{title || "Contact Us"}</h4>
          <p className="mobile-form-description">{description || "Get in touch with us"}</p>
          <form>
            {Object.entries(fields).map(
              ([field, config]) =>
                config.show && (
                  <div key={field} className="add-contact-form-form-group">
                    <label htmlFor={field}>
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                      {config.required && <span className="add-contact-form-required">*</span>}
                    </label>
                    {field === "message" ? (
                      <textarea id={field} rows={4} required={config.required} onChange={(e) => handleChange(field, e.target.value)} />
                    ) : (
                      <input type={field === "email" ? "email" : "text"} id={field} required={config.required} onChange={(e) => handleChange(field, e.target.value)} />
                    )}
                  </div>
                ),
            )}
            <div className="add-contact-form-button-group">
              <button onClick={() => {
                onClose()
                setFormData({})
              }} type="button" className="add-contact-form-cancel-button">
                Cancel
              </button>
              <button onClick={handleSubmit} type="button" className="add-contact-form-next-button">
                Submit
              </button>
            </div>
          </form>
        </div>
    </dialog>
  )
}


import { useState, useEffect } from "react"
import { X, ChevronRight, Search, Trash2, ChevronLeft } from "lucide-react"
import {
  Instagram,
  Threads,
  Snapchat,
  Email,
  Discord,
  Telegram,
  Whatsapp,
  Facebook,
  Youtube,
  Twitter,
  Spotify,
  Linkedin,
  Github,
  Twitch,
  Tiktok
} from "./SocialIcons"

const SOCIAL_PLATFORMS = [
  {
    id: "instagram",
    name: "Instagram",
    icon: Instagram,
    placeholder: "@username",
  },
  {
    id: "threads",
    name: "Threads",
    icon: Threads,
    placeholder: "@username",
  },
  {
    id: "facebook",
    name: "Facebook",
    icon: Facebook,
    placeholder: "Username or profile URL",
  },
  {
    id: "youtube",
    name: "YouTube",
    icon: Youtube,
    placeholder: "Channel URL or username",
  },
  {
    id: "twitter",
    name: "X (Twitter)",
    icon: Twitter,
    placeholder: "@username",
  },
  {
    id: "tiktok",
    name: "TikTok",
    icon: Tiktok,
    placeholder: "@username",
  },
  {
    id: "whatsapp",
    name: "WhatsApp",
    icon: Whatsapp,
    placeholder: "Phone number with country code",
    prefix: "+",
  },
  {
    id: "snapchat",
    name: "Snapchat",
    icon: Snapchat,
    placeholder: "@username",
  },
  {
    id: "linkedin",
    name: "LinkedIn",
    icon: Linkedin,
    placeholder: "Profile URL",
  },
  {
    id: "spotify",
    name: "Spotify",
    icon: Spotify,
    placeholder: "Artist/Profile URL",
  },
  {
    id: "github",
    name: "GitHub",
    icon: Github,
    placeholder: "@username",
  },
  {
    id: "twitch",
    name: "Twitch",
    icon: Twitch,
    placeholder: "@username",
  },
  {
    id: "email",
    name: "Email",
    icon: Email,
    placeholder: "email@example.com",
  },
  {
    id: "discord",
    name: "Discord",
    icon: Discord,
    placeholder: "Username#0000",
  },
  {
    id: "telegram",
    name: "Telegram",
    icon: Telegram,
    placeholder: "@username",
  },
]

export const AddSocialModal = ({ isOpen, onClose, onSave, onEdit, onDelete, existingPlatforms }) => {
  const [selectedPlatform, setSelectedPlatform] = useState(null)
  const [username, setUsername] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [isEditing, setIsEditing] = useState(false)

  const filteredPlatforms = SOCIAL_PLATFORMS.filter((platform) =>
    platform.name.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform)
    // If platform already exists, show edit mode with pre-filled username
    if (existingPlatforms[platform.id]) {
      setIsEditing(true)
      setUsername(existingPlatforms[platform.id].username)
    } else {
      setIsEditing(false)
      setUsername("")
    }
  }

  const handleBack = () => {
    setSelectedPlatform(null)
    setUsername("")
    setIsEditing(false)
  }

  const handleSave = () => {
    if (username.trim()) {
      if (isEditing) {
        onEdit(selectedPlatform.id, username.trim())
      } else {
        onSave({
          platform: selectedPlatform.id,
          username: username.trim(),
        })
      }
      setSelectedPlatform(null)
      setUsername("")
      setIsEditing(false)
      onClose()
    }
  }

  const handleDelete = () => {
    onDelete(selectedPlatform.id)
    setSelectedPlatform(null)
    setUsername("")
    setIsEditing(false)
    onClose()
  }

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setSelectedPlatform(null)
      setUsername("")
      setSearchQuery("")
      setIsEditing(false)
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div className="social-modal-backdrop">
      <div className="social-modal">
        <div className="social-modal-header">
          <button onClick={selectedPlatform ? handleBack : onClose} className="social-modal-back">
            {selectedPlatform && <ChevronLeft />}
          </button>
          <h2 className="social-modal-title">
            {selectedPlatform
              ? isEditing
                ? `Edit ${selectedPlatform.name}`
                : `Add ${selectedPlatform.name}`
              : "Add social profile"}
          </h2>
          <button onClick={onClose} className="social-modal-close">
            <X />
          </button>
        </div>

        <div className="social-modal-content">
          {!selectedPlatform ? (
            <>
              <div className="social-search-container">
                <Search className="social-search-icon" />
                <input
                  type="text"
                  placeholder="Search platforms"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="social-search-input"
                />
              </div>

              <div className="social-platforms-list">
                {filteredPlatforms.map((platform) => (
                  <button
                    key={platform.id}
                    onClick={() => handlePlatformSelect(platform)}
                    className="social-platform-item"
                  >
                    <div className="social-platform-info">
                      <platform.icon className="social-platform-icon" />
                      <span>{platform.name}</span>
                      {existingPlatforms[platform.id] && <span className="social-platform-added">Added</span>}
                    </div>
                    <ChevronRight className="social-platform-arrow" />
                  </button>
                ))}
              </div>
            </>
          ) : (
            <div className="social-input-container">
              <div className="social-input-wrapper">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder={selectedPlatform.placeholder}
                  className="social-username-input"
                />
              </div>
              <p className="social-input-example">Example: {selectedPlatform.placeholder}</p>
              {isEditing && (
                <button onClick={handleDelete} className="social-delete-button">
                  <Trash2 className="social-delete-icon" />
                  Delete
                </button>
              )}
              <button onClick={handleSave} disabled={!username.trim()} className="social-save-button">
                {isEditing ? "Save Changes" : "Add Profile"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

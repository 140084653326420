import React, { useState } from 'react';
import '../../../style/ContactsTable.css'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { getApiUrl } from '../../../config/siteConfig';

const ContactsPage = ({ contacts, refresh, iAmManager }) => {
  const [ showRevokeConfirm, setShowRevokeConfirm ] = useState(false);
  const [ showAccessEdit, setShowAccessEdit ] = useState(false);
  const [ selectedId, setSelectedId ] = useState('');
  const [ failedMessage, setFailedMessage ] = useState('');
  const [ accessValue, setAccessValue ] = useState('');

  const confirmRevoke = id => {
    setShowRevokeConfirm(true);
    setSelectedId(id);
  }

  const closeConfirm = _ => {
    setShowRevokeConfirm(false);
    setShowAccessEdit(false);
  }

  const handleRevoke = async () => {
    const done = await axios({
      method: 'delete',
      url: getApiUrl()+'/api/v1/users/artist/follower/'+selectedId,
      headers: {
        token: window.localStorage.getItem('token') || ''
      }
    });

    if (done && done.data && done.data.done === true) {
      setShowRevokeConfirm(false);
      refresh();
    } else {
      setFailedMessage('Something went wrong! Please check with your admin');
    }
  }

  const editAccess = (id, access) => {
    setShowAccessEdit(true);
    setSelectedId(id);
    setAccessValue(access);
  }

  const updateAccess = event => {
    setAccessValue(event.target.id);
  }

  const handleAccess = async () => {
    const done = await axios({
      method: 'put',
      url: getApiUrl()+'/api/v1/users/follows/access/'+selectedId,
      headers: {
        token: window.localStorage.getItem('token') || ''
      },
      data: {
        access: accessValue
      }
    });

    if (done && done.data && done.data.status === 'successful') {
      setShowAccessEdit(false);
      refresh();
    } else {
      setFailedMessage('Something went wrong! Please check with your admin');
    }
  }

  const getAccessValue = _ => {
    return accessValue;
  }

  return (
    <div style={{ width: '100%'}}>
      <table id="contactTable" className="table">
        <thead className="row header">
          <tr>
            <th className="header-cell">NAME</th>
            <th className="header-cell">TYPE</th>
            <th className="header-cell">ACCESS</th>
            <th className="header-cell"></th>
          </tr>
        </thead>
        <tbody>
          { contacts.map((contact, index) => (
            <tr 
              className="row button-row"
              key={index}
            >
              <td className="cell">{ contact.name || '' }</td>
              <td className="cell">{ contact.accountType || '' }</td>
              <td className="cell">
                <span style={{ cursor: 'pointer', color: 'purple' }} onClick={editAccess.bind(this, contact.id, contact.access)}>{ contact.access || '' }</span>
              </td>
              { !iAmManager && (
                <td className="cell">
                  <Button
                    variant="primary"
                    size="sm" 
                    onClick={confirmRevoke.bind(this, contact.id)}
                  >Revoke</Button>
                </td>
              )}
              { iAmManager && (<td className="cell"></td>) }
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showRevokeConfirm} onHide={closeConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to revoke access?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleRevoke}>
            Confirm
          </Button>
          <Button variant="primary" onClick={closeConfirm}>
            Close
          </Button>

          { failedMessage && (
            <Alert key='danger' variant='danger'>
              { failedMessage }
            </Alert>
          )}
        </Modal.Footer>
      </Modal>
      
      <Modal show={showAccessEdit} onHide={closeConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onChange={updateAccess}>
            <Form.Check
              label="View"
              name="group1"
              type='radio'
              id='view'
              checked={accessValue==='view'}
            />
            <Form.Check
              label="Edit"
              name="group1"
              type='radio'
              id='edit'
              checked={accessValue==='edit'}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAccess}>
            Confirm
          </Button>
          <Button variant="primary" onClick={closeConfirm}>
            Close
          </Button>
          
          { failedMessage && (
            <Alert key='danger' variant='danger'>
              { failedMessage }
            </Alert>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContactsPage;
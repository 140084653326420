import { X } from "lucide-react";
import "./styles.css";
import { useEffect, useRef } from "react";

const DeleteBlockModal = ({ isOpen, onOk, onCancel }) => {
  const dialogRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
      document.body.style.overflow = "hidden";
    } else {
      dialogRef.current?.close();
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <dialog ref={dialogRef} className="delete-block-modal">
      <div className="delete-block-modal-header">
        <h2 className="delete-block-modal-title">Delete Block</h2>
        <button onClick={onCancel} className="delete-block-modal-close-button">
          <X className="delete-block-modal-x" />
        </button>
      </div>
      <p className="delete-modal-content">Are you sure you want to delete this block?</p>
      <div className="delete-modal-button-group">
        <button type="button" onClick={onCancel} className="delete-modal-no-button">
          No
        </button>
        <button type="button" onClick={onOk} className="delete-modal-yes-button">
          Yes
        </button>
      </div>
    </dialog>
  )
}

export default DeleteBlockModal;

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import Catalog from '../components/pages/catalog/Catalog';
import Catalogv2 from '../components/pages/catalog/Catalogv2';
import { userTypePerPage } from '../utils/constants';
import history from '../redux/manageHistory';

const CatalogContainer = (props) => {

  if (props.user && props.user.account) {
    if (!userTypePerPage['catalog'].includes(props.user.account)) {
      return history.push('/dashboard');
    }
  }

  const styles = {
    parentContainer: {
      height: '100%',
      color: '#fff',
      textAlign: 'center',
    },
  };

  return (
    <Row style={styles.parentContainer}>
      <Col xs="12">
       {/*  <Catalog user={props.user} /> */}
       <Catalogv2 user={props.user} />
      </Col>
    </Row>
  );
};

export default CatalogContainer;

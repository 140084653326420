import React from "react"

export const Label = React.forwardRef(({ style, ...props }, ref) => {
    const baseStyles = {
        display: "block",
        fontSize: "0.875rem",
        fontWeight: 500,
        color: "#d1d5db",
        marginBottom: "0.5rem",
    }

    const combinedStyles = {
        ...baseStyles,
        ...style,
    }

    return <label ref={ref} style={combinedStyles} {...props} />
})

Label.displayName = "Label"


import React, { useState, useEffect } from 'react';
import '../../../style/settings.css'
import ContactsPage from './ContactsPage'
import { callApi } from '../../../utils/utils';

export default function Team({props}) {
    const [ followers, setFollowers ] = useState([]);
    const [ followees, setFollowees ] = useState([]);
    
    const getFollowersFollowees = async () => {
        try{
            const resp = await callApi('/api/v1/users/follows?type=artist', 'get');
            if (resp.data) {
                
                const data = resp.data.follows;
                const {followers, followees} = data;
                const allFollowers = followers.map(fer => ({
                    id: fer.id,
                    name: fer.name,
                    accountType: fer.accountType,
                    access: fer.access
                }));
                
                const allFollowees = followees.map(fer => ({
                    id: fer.id,
                    name: fer.name,
                    accountType: fer.accountType,
                    access: fer.access
                }));
        
                setFollowers(allFollowers);
                setFollowees(allFollowees);
            }
        } catch (error) {
            console.error('Error fetching followers and followees', error)
        }
    };

    useEffect(() => {
        getFollowersFollowees();
    }, []);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', color: 'white'}}>
            <div>
                <div>
                    Profiles managing me:
                </div>
                <ContactsPage 
                    contacts={followers}
                    iAmManager={false}
                    refresh={getFollowersFollowees}
                />
            </div>
            <div>
                <div>
                    Profiles I'm managing:
                </div>
                <ContactsPage
                    contacts={followees}
                    iAmManager={true}
                    refresh={getFollowersFollowees}
                />
            </div>
        </div>
    )
};
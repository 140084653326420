import React from "react"

export const Textarea = React.forwardRef(({ style, ...props }, ref) => {
    const baseStyles = {
        display: "flex",
        width: "100%",
        minHeight: "80px",
        borderRadius: "0.375rem",
        border: "1px solid #374151",
        backgroundColor: "#1f2937",
        padding: "0.75rem",
        fontSize: "0.875rem",
        color: "#e5e7eb",
        outline: "none",
        transition: "border-color 0.2s ease",
        resize: "vertical",
        fontFamily: "inherit",
    }

    const combinedStyles = {
        ...baseStyles,
        ...style,
    }

    return <textarea ref={ref} style={combinedStyles} {...props} />
})

Textarea.displayName = "Textarea"


import { useState, useRef, useEffect } from "react";
import { AlignLeft, AlignCenter, Upload, Plus, Check, X } from "lucide-react";
import {
  Switch,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  IconButton,
} from '@mui/material';

import "./styles.css"

const ContentSection = ({
  title = 'Title',
  description = 'Description',
  onTitleChange,
  onDescriptionChange,
}) => {
  return (
    <div className="add-contact-form-content-section">
      <h3 className="add-contact-form-section-title">Content</h3>
      <div className="add-contact-form-form-group">
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => onTitleChange(e.target.value)}
          placeholder="Enter title"
        />
      </div>
      <div className="add-contact-form-form-group">
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => onDescriptionChange(e.target.value)}
          placeholder="Enter description"
          rows={4}
        />
      </div>
    </div>
  )
}

const DataCaptureFields = ({ fields, onFieldChange }) => {
  const [newField, setNewField] = useState("");
  const [isAddingField, setIsAddingField] = useState(false);

  const handleToggle = (field) => {
    onFieldChange({
      ...fields,
      [field]: { ...fields[field], show: !fields[field].show },
    });
  };

  const handleRequiredChange = (field) => {
    onFieldChange({
      ...fields,
      [field]: { ...fields[field], required: !fields[field].required },
    });
  };

  const handleAddField = () => {
    const sanitizedField = newField.trim();
    if (sanitizedField && !fields[sanitizedField]) {
      onFieldChange({
        ...fields,
        [sanitizedField]: { show: true, required: false },
      });
      setNewField("");
      setIsAddingField(false);
    }
  };

  return (
    <div className="add-contact-form-data-capture-fields">
      <h3 className="add-contact-form-section-title">Data Capture Fields</h3>
      <p>
        Add fields to capture data. Mark a field as required to make it mandatory for visitors to
        complete. The form will open in a separate popup.
      </p>

      <List className="add-contact-form-fields-list">
        {Object.entries(fields).map(([field, config]) => (
          <ListItem key={field} className="add-contact-form-field-row">
            <Switch checked={config.show} onChange={() => handleToggle(field)} />
            <ListItemText
              className="add-contact-form-field-name"
              primary={field.charAt(0).toUpperCase() + field.slice(1)}
            />
            <input
              type="checkbox"
              checked={config.required}
              onChange={() => handleRequiredChange(field)}
              disabled={!config.show}
            />
            <span className="add-contact-form-required-label">Required</span>
          </ListItem>
        ))}

        {/* Inline Add Field Row */}
        {isAddingField && (
          <ListItem className="add-contact-form-field-row new-field">
            <TextField
              placeholder="Enter field name"
              value={newField}
              onChange={(e) => setNewField(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              className="add-contact-form-field-input"
              focused
            />
            <IconButton onClick={handleAddField} disabled={!newField.trim()}>
              <Check className="add-contact-form-field-icon" />
            </IconButton>
            <IconButton onClick={() => setIsAddingField(false)}>
              <X className="add-contact-form-field-icon" />
            </IconButton>
          </ListItem>
        )}
      </List>
      {!isAddingField && (
        <Button className={`add-block-button`} onClick={() => setIsAddingField(true)}>
          <Plus className="plus-icon" />
          Add a Field
        </Button>
      )}
    </div>
  )
}

const ImageSection = ({ image, onImageChange }) => {
  const fileInputRef = useRef(null)

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0])
    }
  }

  const handleFile = (file) => {
    if (file.type.startsWith("image/")) {
      const reader = new FileReader()
      reader.onload = (e) => {
        onImageChange(e.target?.result)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="add-contact-form-image-section">
      <h3 className="add-contact-form-section-title">Image</h3>
      <div
        className="profile-image-upload"
        onClick={() => fileInputRef.current?.click()}
      >
          {image ? (
            <img src={image || "/placeholder.svg"} alt="Uploaded" className="add-contact-form-uploaded-image" />
          ) : (
            <div className="add-contact-form-upload-placeholder profile-image-overlay">
              <Upload />
              <span>Add Image</span>
            </div>
          )}
        <input
          type="file"
          id="image-upload"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          className="add-contact-form-hidden-input hidden-input"
        />
      </div>
    </div>
  )
}

const LayoutSection = ({ layout, onLayoutChange }) => {
  return (
    <div className="add-contact-form-layout-section">
      <h3 className="add-contact-form-section-title">Layout</h3>
      <div className="add-contact-form-layout-options">
        <button className={`add-contact-form-layout-button ${layout === "left" ? "active" : ""}`} onClick={() => onLayoutChange("left")}>
          <AlignLeft />
          <span>Left</span>
        </button>
        <button
          className={`add-contact-form-layout-button ${layout === "center" ? "active" : ""}`}
          onClick={() => onLayoutChange("center")}
        >
          <AlignCenter />
          <span>Center</span>
        </button>
      </div>
    </div>
  )
}

const MobilePreview = ({ formData }) => {
  return (
    <>
      <h3 className="add-contact-form-section-title">Mobile Preview</h3>
      <div className="add-contact-form-mobile-preview">
        <div className="add-contact-form-mobile-frame">
          <h4>{formData.title || "Contact Us"}</h4>
          <p>{formData.description || "Get in touch with us"}</p>
          <form>
            {Object.entries(formData.fields).map(
              ([field, config]) =>
                config.show && (
                  <div key={field} className="add-contact-form-form-group">
                    <label htmlFor={field}>
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                      {config.required && <span className="add-contact-form-required">*</span>}
                    </label>
                    {field === "message" ? (
                      <textarea id={field} rows={4} required={config.required} />
                    ) : (
                      <input type={field === "email" ? "email" : "text"} id={field} required={config.required} />
                    )}
                  </div>
                ),
            )}
            <div className="add-contact-form-button-group">
              <button type="button" className="add-contact-form-cancel-button">
                Cancel
              </button>
              <button type="button" className="add-contact-form-next-button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const ModulePreview = ({ formData }) => {
  return (
    <>
      <h3 className="add-contact-form-section-title">Module Preview</h3>
      <div className="add-contact-form-module-preview">
        <div className={`add-contact-form-preview-content ${formData.layout}`}>
          {formData.image && (
            <div className={`add-contact-form-preview-image ${formData.layout}`}>
              <img src={formData.image || "/placeholder.svg"} alt="Preview" />
            </div>
          )}
          <div className={`add-contact-form-preview-text ${formData.layout}`}>
            <h4>{formData.title || "Title"}</h4>
            <p>{formData.description || "Description"}</p>
          </div>
        </div>
      </div>
    </>
  )
}

const AddContactFormDialog = ({ isOpen, onClose, onSave, initialData }) => {
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState({
    layout: "left",
    image: "",
    title: "",
    description: "",
    fields: {
      name: { show: true, required: true },
      email: { show: true, required: true },
      mobile: { show: false, required: false },
      message: { show: true, required: false },
      country: { show: false, required: false },
    },
  })
  const [_, setErrors] = useState({}) // Add validation errors for Form Preview
  const dialogRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal()
      document.body.style.overflow = "hidden"
      setFormData({
        description: initialData?.description || "",
        image: initialData?.image || "",
        layout: initialData?.layout || "left",
        title: initialData?.title || "",
        fields: initialData?.fields || {
          name: { show: true, required: true },
          email: { show: true, required: true },
          mobile: { show: false, required: false },
          message: { show: true, required: false },
          country: { show: false, required: false },
        }
      });
    } else {
      dialogRef.current?.close()
      document.body.style.overflow = "unset"
    }
  }, [isOpen, initialData])

  const handleClose = () => {
    setPage(1)
    setFormData({
      layout: "left",
      image: "",
      title: "",
      description: "",
      fields: {
        name: { show: true, required: true },
        email: { show: true, required: true },
        mobile: { show: false, required: false },
        message: { show: true, required: false },
        country: { show: false, required: false },
      },
    })
    setErrors({})
    onClose()
  }

  const validateForm = () => {
    const newErrors = {}
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSave = () => {
    if (validateForm()) {
      onSave(formData)
      setPage(1)
      setFormData({
        layout: "left",
        image: "",
        title: "",
        description: "",
        fields: {
          name: { show: true, required: true },
          email: { show: true, required: true },
          mobile: { show: false, required: false },
          message: { show: true, required: false },
          country: { show: false, required: false },
        },
      })
      handleClose()
    }
  }

  const handleNext = () => {
    if (validateForm()) {
      setPage(2)
    }
  }

  const handlePrevious = () => {
    setPage(1)
  }

  return (
    <dialog ref={dialogRef} className="add-contact-form-dialog">
      <div className="add-contact-form-header">
        <h2 className="add-contact-form-title">{initialData ? 'Edit Contact Form' : 'Add New Contact Form'}</h2>
        <button onClick={handleClose} className="add-contact-form-close-button">
          <X className="add-contact-form-x" />
        </button>
      </div>
      {page === 1 ? (
        <div className="add-contact-form-dialog-content">
          <div className="add-contact-form-left-side">
            <LayoutSection layout={formData.layout} onLayoutChange={(layout) => setFormData({ ...formData, layout })} />
            <ImageSection image={formData.image} onImageChange={(image) => setFormData({ ...formData, image })} />
            <ContentSection
              title={formData.title}
              description={formData.description}
              onTitleChange={(title) => setFormData({ ...formData, title })}
              onDescriptionChange={(description) => setFormData({ ...formData, description })}
            />
          </div>
          <div className="add-contact-form-right-side">
            <ModulePreview formData={formData} />
          </div>
        </div>
      ) : (
        <div className="add-contact-form-dialog-content">
          <div className="add-contact-form-left-side">
            <DataCaptureFields
              fields={formData.fields}
              onFieldChange={(fields) => setFormData({ ...formData, fields })}
            />
          </div>
          <div className="add-contact-form-right-side">
            <MobilePreview formData={formData} />
          </div>
        </div>
      )}
      <div className="add-contact-form-button-group">
        {page === 1 ? (
          <>
            <button type="button" onClick={handleClose} className="add-contact-form-cancel-button">
              Cancel
            </button>
            <button type="button" onClick={handleNext} className="add-contact-form-next-button">
              Next
            </button>
          </>
        ) : (
          <>
            <button type="button" onClick={handlePrevious} className="add-contact-form-previous-button">
              Previous
            </button>
            <button type="button" onClick={handleSave} className="add-contact-form-save-button">
              Save
            </button>
          </>
        )}
      </div>
    </dialog>
  )
}

export default AddContactFormDialog

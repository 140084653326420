import { callApi } from '../../../utils/utils';
import { useState, useEffect } from "react"

const FansPage = () => {
  const [hoveredRow, setHoveredRow] = useState(null)
  const [hoveredDelete, setHoveredDelete] = useState(null)
  const [apiData, setApiData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selectedFan, setSelectedFan] = useState(null)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [downloadingCSV, setDownloadingCSV] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi('/api/v1/users/follows?type=fan', 'get');
        console.log('API Response:', response);
        
        // Extract the data from the response
        const { data } = response;
        
        if (!data) {
          throw new Error('No data received from API');
        }

        if (data.status === 'successful' && data.follows) {
          const { followees } = data.follows;
          console.log('Followees:', followees);
          
          // For fans page, we'll use followees (people being managed)
          setApiData(followees || []);
        } else {
          throw new Error(data.message || 'Failed to fetch data');
        }
        
      } catch (err) {
        console.error('API Error:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const handleDeleteClick = (e, fan) => {
    e.stopPropagation(); // Prevent row click from triggering
    setSelectedFan(fan);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedFan || !selectedFan.id) {
      console.error("No fan selected or fan ID is missing");
      setShowConfirmation(false);
      return;
    }

    setDeleteLoading(true);

    try {
      const response = await callApi(`/users/fan/followee/${selectedFan.id}`, 'delete');

      if (response.data && response.data.status === 'successful') {
        // Remove the deleted fan from the state
        setApiData(prevData => prevData.filter(fan => fan.id !== selectedFan.id));
      } else {
        throw new Error(response.data?.message || 'Failed to delete fan');
      }
    } catch (err) {
      console.error('Delete API Error:', err);
      setError(`Failed to delete fan: ${err.message}`);
    } finally {
      setDeleteLoading(false);
      setShowConfirmation(false);
      setSelectedFan(null);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setSelectedFan(null);
  };

  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    if (!data || data.length === 0) return '';
    
    // Define the columns we want to include in the CSV
    const columns = [
      'name',
      'email',
      'accountType',
      'phone',
      'location',
      'lastMessage',
      'messages',
      'id',
      'knktId',
      'firstName',
      'lastName'
    ];
    
    // Create header row
    const header = columns.map(col => `"${col}"`).join(',');
    
    // Create data rows
    const rows = data.map(item => {
      return columns.map(col => {
        const value = item[col] !== undefined ? item[col] : '';
        // Wrap values in quotes and escape any existing quotes
        return `"${String(value).replace(/"/g, '""')}"`;
      }).join(',');
    });
    
    // Combine header and rows
    return [header, ...rows].join('\n');
  };

  // Function to trigger the CSV download
  const downloadCSV = () => {
    if (!apiData || apiData.length === 0) {
      console.error('No data available to download');
      return;
    }
    
    setDownloadingCSV(true);
    
    try {
      // Convert the data to CSV format
      const csvContent = convertToCSV(apiData);
      
      // Create a Blob containing the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      
      // Set the download attribute with filename
      const date = new Date().toISOString().slice(0, 10); // YYYY-MM-DD format
      link.download = `fans_data_${date}.csv`;
      
      // Append the link to the body
      document.body.appendChild(link);
      
      // Trigger the download
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setDownloadingCSV(false);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="text-center p-4">
          Loading...
        </div>
      )
    }

    if (error) {
      return (
        <div className="text-red-500 p-4">
          Error loading data: {error}
        </div>
      )
    }

    if (!apiData || apiData.length === 0) {
      return (
        <div className="text-center p-4">
          No fans data available
        </div>
      )
    }

    // If we have data, render the table
    return (
      <div className="w-full">
        {/* Table Header */}
        <div className="flex font-bold text-black bg-white">
          <div className="flex-1 p-3">NAME</div>
          <div className="flex-1 p-3">EMAIL</div>
          <div className="flex-1 p-3">ACCOUNT TYPE</div>
          <div className="flex-1 p-3 hidden md:block">PHONE</div>
          <div className="flex-1 p-3 hidden md:block">LOCATION</div>
          <div className="flex-1 p-3 hidden md:block">LAST MESSAGE</div>
          <div className="flex-1 p-3 hidden md:block">MESSAGES</div>
          <div className="w-12 p-3"></div>
        </div>

        {/* Table Rows */}
        {apiData.map((contact, index) => (
          <div
            key={index}
            onMouseEnter={() => setHoveredRow(index)}
            onMouseLeave={() => setHoveredRow(null)}
            onClick={() => handleRowClick(contact)}
            className={`
              flex flex-row items-center border-b border-gray-600
              transition-all duration-200 cursor-pointer
              bg-black
              ${hoveredRow === index ? "ring-2 ring-[#8627df] ring-offset-2 ring-offset-black" : ""}
            `}
          >
            <div className="flex-1 p-3 break-words">{contact.name || `${contact.firstName} ${contact.lastName}` || '-'}</div>
            <div className="flex-1 p-3 break-words">{contact.email || '-'}</div>
            <div className="flex-1 p-3 break-words">{contact.accountType || '-'}</div>
            <div className="flex-1 p-3 break-words hidden md:block">{contact.phone || "-"}</div>
            <div className="flex-1 p-3 break-words hidden md:block">{contact.location || "-"}</div>
            <div className="flex-1 p-3 break-words hidden md:block">{contact.lastMessage || "-"}</div>
            <div className="flex-1 p-3 break-words hidden md:block">{contact.messages || "-"}</div>
            <div className="w-12 p-2 flex justify-start pl-1">
              <button
                className={`w-8 h-8 flex items-center justify-center rounded-full
                  border-2 border-red-800 
                  ${hoveredDelete === index ? 'bg-red-800' : 'bg-transparent'} 
                  text-white transition-all duration-200 mr-2`}
                onMouseEnter={() => setHoveredDelete(index)}
                onMouseLeave={() => setHoveredDelete(null)}
                onClick={(e) => handleDeleteClick(e, contact)}
                aria-label="Delete fan"
              >
                X
              </button>
            </div>
          </div>
        ))}
      </div>
    )
  }

  // Handle row click
  const handleRowClick = (contact) => {
    console.log("Row clicked:", contact);
    // Add your row-click logic here
  }

  return (
    <div className="min-h-screen text-white px-4 py-4">
      <div className="max-w-6xl mx-auto" style={{maWidth: "90%"}}>
        {/* NAVBAR / CONTROLS */}
        <nav className="flex justify-between items-center border-b border-gray-500 pb-2 mb-4">
          <div className="flex space-x-6 uppercase">
            <button className="hover:text-[#fbb655] transition-colors">
              Campaigns
            </button>
            <button className="hover:text-[#fbb655] transition-colors">
              My Contacts
            </button>
          </div>

          <button 
            className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-orange-500 transition-colors"
            onClick={downloadCSV}
            disabled={downloadingCSV || isLoading || !apiData || apiData.length === 0}
          >
            {downloadingCSV ? "Downloading..." : "Download CSV"}
          </button>
        </nav>

        {/* Render content based on API state */}
        {renderContent()}

        {/* Confirmation Modal */}
        {showConfirmation && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full">
              <h3 className="text-xl font-bold mb-4">Confirm Delete</h3>
              <p className="mb-6">
                Are you sure you want to remove {selectedFan?.name || 
                (selectedFan?.firstName && selectedFan?.lastName 
                  ? `${selectedFan.firstName} ${selectedFan.lastName}` 
                  : 'this fan')} 
                from your community?
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition-colors"
                  onClick={handleCancelDelete}
                  disabled={deleteLoading}
                >
                  No
                </button>
                <button
                  className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                  onClick={handleConfirmDelete}
                  disabled={deleteLoading}
                >
                  {deleteLoading ? "Deleting..." : "Yes"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FansPage
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import MapChart from './map/MapChart';

const KnktMap = props => {
  const [content, setContent] = useState("");

  const styles = {
    knktMap: {
      height: 350, textAlign: 'center',/*  backgroundColor: '#1a1d24' */
    }
  };

  return (
    <div style={{ ...styles.knktMap, ...props.customParentStyle }}>
      <MapChart
        setTooltipContent={setContent}
        customMapStyle={props.customMapStyle}
        markerType="dropper"
        markerColor='#FF5533'
        scale={200}
      />
      <ReactTooltip>{content}</ReactTooltip>
    </div>
  )
};

export default KnktMap;
import { React, useState, useEffect } from "react";

import KnktChart from "../components/common/charts/KnktChart";
import { userTypePerPage } from '../utils/constants';
import history from '../redux/manageHistory';

const styles = {
  parentContainer: {
    height: "100%",
    color: "#fff",
    textAlign: "center",
    marginTop: "1rem",
  },
  gridBox: {
    /* backgroundColor: "#000000", */
    backgroundColor: "rgba(17, 24, 39, 0.5)",
    backdropFilter: "blur(8px)",
    border: "2px solid #1f2937",
    color: "white",
    padding: "1rem",
  },
};

const knktLineChartOptions = {
  xAxis: {
    type: "category",
    axisLabel: {
      show: true,
    },
    axisTick:{
      show: false
    },
    axisLine: {
      lineStyle: {
        color: "#c4c4c4",
      },
    },
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yAxis: {
    type: "value",
    axisLabel: {
      show: true,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
    axisLine: {
      lineStyle: {
        color: "#c4c4c4",
      },
    }
  },
  tooltip: {
    trigger: 'axis'
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: "line",
      smooth: true,
    },
  ],
};

const knktRadarChartOptions = {
  title: {
    text: 'Basic Radar Chart'
  },
 /* backgroundColor: "rgba(17, 24, 39, 0.5)",  */
  legend: {
    data: ['Facebook', 'Instagram', 'Deezer', 'Soundcloud','Youtube'],
    bottom: 0
  },
  radar: {
    // shape: 'circle',
    splitNumber: 1,
    splitArea:{
      areaStyle:{
        color: 'inherit'
      }
    },
    indicator: [
      { name: 'Audience Size', max: 33 },
      { name: 'Post Quantity', max: 33 },
      { name: 'Engagement Score', max: 33 },
    ]
  },
  series: [
    {
      name: 'Social Media Platform Scores',
      type: 'radar',
      data: [
        {
          value: [10, 12, 25],
          name: 'Facebook',
          areaStyle: {
            color: 'blue'
          },
        },
        {
          value: [5, 15, 9],
          name: 'Instagram',
          areaStyle: {
            color: 'green'
          },
        },
         {
          value: [21, 8, 7],
          name: 'Soundcloud',
          areaStyle: {
            color: 'orange'
          },
        },
         {
          value: [4, 7, 25],
          name: 'Youtube',
          areaStyle: {
            color: 'red'
          },
        },
      ]
    }
  ]
};

const knktScoreChartOptions = {
  tooltip: {
    formatter: "{a} <br/>{b} : {c}%",
  },
  series: [
    {
      name: "Pressure",
      type: "gauge",
      pointer: {
        length: "50%",
        width: "1%",
        itemStyle: {
          color: "inherit",
        },
        show: false,
      },
      axisTick: { show: false },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          width: "25",
          color: [
            [0.25, "#FF6E76"],
            [0.5, "#FDDD60"],
            [0.75, "#58D9F9"],
            [1, "#7CFFB2"],
          ],
        },
      },
      title: {
        fontSize: 10,
        offsetCenter: [0, "60%"],
        color: "#c4c4c4",
      },
      detail: {
        valueAnimation: true,
        color: "#FDDD60",
        offsetCenter: [0, "25%"],
        fontSize: 65,
      },
      data: [
        {
          value: 50,
          name: "KNKT SCORE \n\n last updated June 23, 2023",
        },
      ],
    },
  ],
};
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

const CatalogContainer = (props) => {
  const isDesktop = useMediaQuery("(min-width: 900px)");

  if (props.user && props.user.account) {
    if (!userTypePerPage['score'].includes(props.user.account)) {
      return history.push('/dashboard');
    }
  }

  let dynamicStyles = {
    gridContainer: {
      // maxWidth: "1200px",
      margin: "1rem",
      display: "grid",
      gridGap: "1rem",
      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    },
  };

  dynamicStyles.gridContainer.gridTemplateColumns = isDesktop
    ? "repeat(auto-fit, minmax(300px, 1fr))"
    : "repeat(1, 1fr)";

  return (
    <div style={styles.parentContainer}>
      <div style={dynamicStyles.gridContainer}>
        <div style={styles.gridBox}>
          <KnktChart option={knktRadarChartOptions} />
        </div>
{/*         <div style={styles.gridBox}>
          <KnktChart option={knktChartOptions} />
        </div>
        <div style={styles.gridBox}>
          <KnktChart option={knktChartOptions} />
        </div> */}
        <div style={styles.gridBox}>
          <KnktChart option={knktLineChartOptions} />
        </div>
        {/*  <div style={styles.gridBox}>THREE</div>
        <div style={styles.gridBox}>FOUR</div>
       <div style={styles.gridBox}>FIVE</div>
        <div style={styles.gridBox}>SIX</div>
        <div style={styles.gridBox}>SEVEN</div>
        <div style={styles.gridBox}>EIGHT</div>
        <div style={styles.gridBox}>NINE</div>
        <div style={styles.gridBox}>TEN</div>
        <div style={styles.gridBox}>ELEVEN</div>
        <div style={styles.gridBox}>TWELVE</div> */}
      </div>
    </div>
  );
};

export default CatalogContainer;

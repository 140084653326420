import React from 'react';

const TabHeader = props => {
  const styles = {
    tabHeaders: {
      display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'stretch',
      height: 54,
      overflow: 'auto'
    },
    tabHeading: {
      flexDirection: 'row',
      flexWrap: 'no-wrap',
      width: '100%',
      outline: 'none',
      textDecoration: 'none',

      color: '#ffffff',
      display: 'inline-block',
      marginLeft: 5,
      marginTop: 5,
      textAlign: 'center',
      padding: 5
    },
    tabHeaderFirst: {
      marginLeft: 0
    },
    btnLink: {
      color: '#ffffff',
      textTransform: 'uppercase',
     /*  backgroundColor: '#666666' */
     backgroundColor: "rgba(17, 24, 39, 0.5)",
      backdropFilter: "blur(8px)",
      border: "1px solid #1f2937",
    },
    btnActive: {
      color: '#05c2fb',
      border: 0,
      cursor: 'default',
      /* backgroundColor: '#2c2c2c' */
      backgroundColor: "rgba(17, 24, 39, 0.5)",
      backdropFilter: "blur(8px)",
      border: "1px solid #1f2937",
    },
    btnInActive: {
      borderColor: '#3d3d3d',
      borderStyle: 'solid',
      borderTop: 'solid initial 1px',
      borderLeft: 'solid initial 1px',
      borderRight: 'solid initial 1px',
      borderBottom: 0,
      backdropFilter: "blur(1px)",
      cursor: 'pointer'
    }
  };

  let customTabHeaders = {};
  let customTabHeading = {};
  let customBtnActive = {};
  let customBtnInActive = {};

  // useEffect(() => {

  // }, []);

  const tabsHeadingOnClick = (e, item) => {
    e.preventDefault();

    if (props.onFansTabSelected) {
      props.onFansTabSelected(item);
    }
  };

  if (props.customStyle) {
    if (props.customStyle.tabHeaders) {
      customTabHeaders = props.customStyle.tabHeaders;
    }
    if (props.customStyle.tabHeading) {
      customTabHeading = props.customStyle.tabHeading;
    }
    if (props.customStyle.btnActive) {
      customBtnActive = props.customStyle.btnActive;
    }
    if (props.customStyle.btnInActive) {
      customBtnInActive = props.customStyle.btnInActive;
    }
  }

  return (
    <div style={{...styles.tabHeaders, ...customTabHeaders}}>
      {
        props.tabData && props.tabData.map((item, i) => {
          const active = item.key === props.selectedFansTab ? {...styles.btnActive, ...customBtnActive} : {...styles.btnInActive, ...customBtnInActive};
          const first = i === 0 ? styles.tabHeaderFirst : {};

          return (
            <button
              className="btn-link"
              key={item.key}
              style={{...styles.btnLink, ...styles.tabHeading, ...customTabHeading, ...active, ...first}}
              onClick={(e) => tabsHeadingOnClick(e, item)}
            >
              {item.text}
            </button>
          )
        })
      }
    </div>
  )
};

export default TabHeader;
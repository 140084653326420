import * as SocialIcons from "./SocialIcons"

export const SocialIcon = ({ platform, username }) => {
  const Icon = SocialIcons[platform.charAt(0).toUpperCase() + platform.slice(1)]

  const getSocialUrl = (platform, username) => {
    const cleanUsername = username.replace("@", "")
    switch (platform) {
      case "instagram":
        return `https://instagram.com/${cleanUsername}`
      case "threads":
        return `https://threads.net/@${cleanUsername}`
      case "facebook":
        return username.startsWith("http") ? username : `https://facebook.com/${cleanUsername}`
      case "twitter":
        return `https://twitter.com/${cleanUsername}`
      case "youtube":
        return username.startsWith("http") ? username : `https://youtube.com/@${cleanUsername}`
      case "tiktok":
        return `https://tiktok.com/@${cleanUsername}`
      case "whatsapp":
        const cleanPhone = username.replace(/\D/g, "")
        return `https://wa.me/${cleanPhone}`
      case "snapchat":
        return `https://snapchat.com/add/${cleanUsername}`
      case "linkedin":
        return username.startsWith("http") ? username : `https://linkedin.com/in/${cleanUsername}`
      case "spotify":
        return username.startsWith("http") ? username : `https://open.spotify.com/user/${cleanUsername}`
      case "github":
        return `https://github.com/${cleanUsername}`
      case "twitch":
        return `https://twitch.tv/${cleanUsername}`
      case "email":
        return `mailto:${username}`
      case "discord":
        return `discord://users/${username}`
      case "telegram":
        return `https://t.me/${cleanUsername}`
      default:
        return null
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    const url = getSocialUrl(platform, username)
    if (url) window.open(url, "_blank")
  }

  return (
    <button onClick={handleClick} className="social-icon-button" data-platform={platform}>
      <Icon className="social-icon" />
    </button>
  )
}

import React, { useState, useEffect } from 'react';
import ReactCountryFlag from "react-country-flag";
import { Zoom } from "react-awesome-reveal";

import { getChangeValues, capitalizeFirstLetter } from '../../../../utils/utils';

const CountryCityTable = props => {
  const [locationData, setLocationData] = useState(props.locationData);
  const [selectedTabApiKey, setSelectedTabApiKey] = useState(props.locationData);
  const [dataType] = useState(props.dataType);

  useEffect(() => {
    // setLocationData(props.locationData);
    // setSelectedTabApiKey(props.selectedTabApiKey);
    // setDataType(props.dataType);
  }, []);

  useEffect(() => {
    setLocationData(props.locationData);
    setSelectedTabApiKey(props.selectedTabApiKey);
    // setDataType(props.dataType);
  }, [props.selectedTabApiKey, props.locationData]);

  const styles = {
    countryTable: {
      marginTop: 20,
      padding: 10,
      /*  backgroundColor: '#1a1d24', */
      backgroundColor: "rgba(17, 24, 39, 0.5)",
      backdropFilter: "blur(8px)",
      border: "1px solid #1f2937",
      color: '#ffffff'
    },
    title: {
      font: 'bolder 18px sans-serif',
      width: '100%',
      textAlign: 'center'
    },
    country: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 15
    },
    countryFlag: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    flag: {
      marginRight: 20,
      width: 30,
      height: 30,
      borderRadius: 15,
      // border: '1px solid #000000',
      // display: 'block'
      overflow: 'hidden'
    }
  };

  return (
    <div style={styles.countryTable}>
      <Zoom>
        <div style={styles.title}>{capitalizeFirstLetter(dataType)}</div>
      </Zoom>
      {
        selectedTabApiKey &&
        locationData[selectedTabApiKey] &&
        locationData[selectedTabApiKey][dataType] && locationData[selectedTabApiKey][dataType].map((data, i) => {
          const { change, changeStyle } = getChangeValues(data.increasePercentage);
          return (
            <div id={i} key={i} style={styles.country}>
              <div style={styles.countryFlag}>
                {dataType === 'countries' && (
                  <div style={styles.flag}>
                    <ReactCountryFlag
                      code={data.countryCode}
                      styleProps={{
                        width: 40,
                        height: 30,
                        backgroundPosition: 'center center',
                        left: -5
                      }}
                      svg
                    />
                  </div>
                )}
                <div>
                  <div>{data.name}</div>
                  <div style={changeStyle}>{typeof change === 'number' ? ((change > 0 ? '+' : '') + change.toFixed(2)) : ''} %</div>
                </div>
              </div>
              <div>{typeof data.totPercentage === 'number' ? ((data.totPercentage > 0 ? '+' : '') + data.totPercentage.toFixed(2)) : ''} %</div>
            </div>
          )
        })
      }
    </div>
  )
}

export default CountryCityTable;
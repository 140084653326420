import { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Radium from 'radium';

import { userTypePerPage } from '../utils/constants';
import history from '../redux/manageHistory';

const styles = {
  promoteWrapper: {
    color: '#ffffff',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    minHeight: '94vh',
  },
  linkWrapper: {
    color: '#ffffff',
    textDecoration: 'none',
  },
  connectText: {
    fontSize: '16px',
  },
  promoteTitleContainer: {
    textAlign: 'center',
    padding: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  promoteTitle: {
    margin: '0 auto',
  },
  linksParent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  linksContainer: {
    borderRadius: '1rem',
    height: 217,
    margin: 15,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    gap: '3px',
    transition: 'all 300ms ease',
  },
  hover: {
    fb: {
      background:
        'linear-gradient(17deg, rgba(60, 0, 255, 0.85) 0%, rgb(134, 1, 255) 26%, rgb(255, 10, 103) 86%)',
      ':hover': {
        background:
          'linear-gradient(17deg, rgba(60, 0, 255, 0.85) 0%, rgb(134, 1, 255) 26%, rgb(255, 10, 103) 86%)',
        cursor: 'pointer',
        boxShadow: '0px 0px 15px 6px rgb(134, 1, 255)',
        opacity: 1,
      },
    },
    upcoming: {
      background:
        'linear-gradient(17deg, rgb(255, 10, 103) 0%, rgb(1, 68, 255) 68%, rgba(255, 0, 0, 0.85) 86%)',
      ':hover': {
        boxShadow: '0px 0px 15px 6px rgb(255, 10, 103)',
        background:
          'linear-gradient(17deg, rgb(255, 10, 103) 0%, rgb(1, 68, 255) 68%, rgba(255, 0, 0, 0.85) 86%)',
        cursor: 'pointer',
      },
    },
    ig: {
      background:
        'linear-gradient(to right, rgb(236, 47, 75), rgb(0, 159, 255))',
      ':hover': {
        boxShadow: '0px 0px 15px 6px gray',
        background:
          'linear-gradient(to right, rgb(236, 47, 75), rgb(0, 159, 255))',
        cursor: 'pointer',
      },
    },
    page: {
      background:
        'linear-gradient(17deg, rgba(76,46,191,1) 16%, rgba(149,46,191,1) 70%, rgba(253,158,29,1) 100%)',
      ':hover': {
        boxShadow: '0px 0px 15px 6px rgb(76,46,191)',
        background:
          'linear-gradient(17deg, rgba(76,46,191,1) 16%, rgba(149,46,191,1) 70%, rgba(253,158,29,1) 100%)',
        cursor: 'pointer',
      },
    },
    poll: {
      background:
        'linear-gradient(149deg, rgb(255 0 0) 19%, rgb(0 43 255) 91%, rgb(9 96 255) 100%)',
      ':hover': {
        boxShadow: '0px 0px 15px 6px rgb(10,117,244)',
        background:
          'linear-gradient(149deg, rgb(255 0 0) 19%, rgb(0 43 255) 91%, rgb(9 96 255) 100%)',
        cursor: 'pointer',
      },
    },
    charts: {
      background:
        'linear-gradient(212deg, rgb(66, 27, 255) 17%, rgb(255, 0, 0) 100%)',
      ':hover': {
        boxShadow: '0px 0px 15px 6px rgb(255, 0, 0)',
        background:
          'linear-gradient(212deg, rgb(66, 27, 255) 17%, rgb(255, 0, 0) 100%)',
        cursor: 'pointer',
      },
    },
    eventBrite: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px #F05537',
        background: '#F05537',
        cursor: 'pointer',
      },
    },
    shows: {
      background:
        'linear-gradient(40deg, rgba(154,49,255,1) 0%, rgba(63,94,251,1) 58%, rgba(252,70,107,1) 91%)',
      ':hover': {
        boxShadow: '0px 0px 15px 6px rgb(154,49,255)',
        background:
          'linear-gradient(40deg, rgba(154,49,255,1) 0%, rgba(63,94,251,1) 58%, rgba(252,70,107,1) 91%)',
        cursor: 'pointer',
        textDecoration: 'none',
      },
    },
    deezer: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px gray',
        background: '#000',
        cursor: 'pointer',
      },
    },
  },
  optionsTitle: {
    marginBottom: 30,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  optionsError: {
    color: '#ff0000',
    marginTop: 20,
  },
  soundCloudError: {
    marginTop: 30,
    color: '#ff0000',
  },
  soundCloudForm: {
    marginBottom: 20,
    color: '#ffffff',
  },
  soundCloudFormBackground: {
    backgroundColor: '#7a7a7a',
  },
  soundCloudModalHeader: {
    borderBottom: 'none',
  },
  soundCloudFooter: {
    textAlign: 'center',
  },
  soundCloudConnectButton: {
    backgroundColor: '#000000',
  },
  soundCloudModal: {},
  createPromoButton: {
    background: 'linear-gradient(17deg, rgba(60, 0, 255, 0.85) 0%, rgb(134, 1, 255) 26%, rgb(255, 10, 103) 86%)',
    borderRadius: '5px',
    fontSize: '14px',
  },
  createPromoLink: {
    display: 'flex',
    gap: '8px',
    padding: '10px',
  }
};

const CreatePromoContainer = (props) => {
  const [showCreate, setShowCreate] = useState(false);
  const [hoverCard, setHoverCard] = useState('');

  if (props.user && props.user.account) {
    if (!userTypePerPage['promote'].includes(props.user.account)) {
      return history.push('/dashboard');
    }
  }

  return ( 
    <div style={styles.promoteWrapper}>
      <Row>
        <Col xs={6} sm={4} md={3}>
          <Link to="/calendar" style={styles.linkWrapper}>
            <div
              id="spotify"
              key={'1'}
              style={[styles.linksContainer, styles.hover.poll]}
              onMouseEnter={() => {
                setShowCreate(true);
                setHoverCard('poll');
              }}
              onMouseLeave={() => {
                setShowCreate(false);
                setHoverCard('');
              }}
            >
              <i class="fas fa-music fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">Poll</h4>
              <h4
                style={{
                  visibility:
                    showCreate && hoverCard === 'poll'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                + Create
              </h4>
            </div>
          </Link>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Link to="/promolink" style={styles.linkWrapper}>
            <div
              id="spotify"
              key={'2'}
              style={[styles.linksContainer, styles.hover.fb]}
              onMouseEnter={() => {
                setShowCreate(true);
                setHoverCard('release');
              }}
              onMouseLeave={() => {
                setShowCreate(false);
                setHoverCard('');
              }}
            >
              <i class="fab fa-spotify fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">Release</h4>
              <h4
                style={{
                  visibility:
                    showCreate && hoverCard === 'release'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                + Create
              </h4>
            </div>
          </Link>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Link to="/calendar" style={styles.linkWrapper}>
            <div
              id="spotify"
              key={'3'}
              style={[styles.linksContainer, styles.hover.upcoming]}
              onMouseEnter={() => {
                setShowCreate(true);
                setHoverCard('upcoming');
              }}
              onMouseLeave={() => {
                setShowCreate(false);
                setHoverCard('');
              }}
            >
              <i class="fab fa-spotify fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">Upcoming</h4>
              <h4
                style={{
                  visibility:
                    showCreate && hoverCard === 'upcoming'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                + Create
              </h4>
            </div>
          </Link>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Link to="/createtemplate" style={styles.linkWrapper}>
            <div
              id="spotify"
              key={'4'}
              style={[styles.linksContainer, styles.hover.page]}
              onMouseEnter={() => {
                setShowCreate(true);
                setHoverCard('page');
              }}
              onMouseLeave={() => {
                setShowCreate(false);
                setHoverCard('');
              }}
            >
              <i class="fab fa-spotify fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">Link Page</h4>
              <h4
                style={{
                  visibility:
                    showCreate && hoverCard === 'page'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                + Create
              </h4>
            </div>
          </Link>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Link to="/calendar" style={styles.linkWrapper}>
            <div
              id="spotify"
              key={'5'}
              style={[styles.linksContainer, styles.hover.charts]}
              onMouseEnter={() => {
                setShowCreate(true);
                setHoverCard('charts');
              }}
              onMouseLeave={() => {
                setShowCreate(false);
                setHoverCard('');
              }}
            >
              <i class="fas fa-headphones fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">Charts</h4>
              <h4
                style={{
                  visibility:
                    showCreate && hoverCard === 'charts'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                + Create
              </h4>
            </div>
          </Link>
        </Col>
        <Col xs={6} sm={4} md={3}>
          <Link to="/calendar" style={styles.linkWrapper}>
            <div
              id="spotify"
              key={'6'}
              style={[styles.linksContainer, styles.hover.shows]}
              onMouseEnter={() => {
                setShowCreate(true);
                setHoverCard('shows');
              }}
              onMouseLeave={() => {
                setShowCreate(false);
                setHoverCard('');
              }}
            >
              <i class="fas fa-ticket-alt fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">Shows</h4>
              <h4
                style={{
                  visibility:
                    showCreate && hoverCard === 'shows'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                + Create
              </h4>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
   );
}
 
export default Radium(CreatePromoContainer);
import React, { useState, useEffect } from 'react';
import { ChevronRight } from 'lucide-react';
import { ArtistCard } from './ArtistCard';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../../ui/pagination';
import { fetchArtistProfiles } from '../../../utils/communityUtils';

const ArtistHome = ({ user, filteredContent, isFiltered, getArtistProfileData, onSelectArtist }) => {
  const [artistProfiles, setArtistProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  
  const pageLimit = 9;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchArtistProfiles(currentPage, pageLimit);
        if (result && result.artistProfiles.length > 0) {
          setArtistProfiles(result.artistProfiles);
          getArtistProfileData(result.artistProfiles);
          setTotalPages(result.totalPages);
        } else {
          setArtistProfiles([]);
          setTotalPages(0);
        }
      } catch (error) {
        console.error('Error fetching artist profiles:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isFiltered) {
      fetchData();
    }
  }, [currentPage, isFiltered]);

  const displayedArtists = isFiltered ? filteredContent : artistProfiles;
  const filteredArtists = displayedArtists.filter(artist =>
    artist.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="min-h-screen p-6">
      <div className="mx-auto space-y-6">

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredArtists.map((artist) => (
            <ArtistCard
              key={artist.id || artist._id}
              artist={artist}
              onSelectArtist={onSelectArtist}
            />
          ))}
        </div>

        {!isFiltered && (
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious 
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  className={`bg-gray-900 border-gray-800 text-gray-100 hover:bg-gray-800 hover:border-purple-500 transition-colors ${currentPage === 1 ? 'pointer-events-none opacity-50' : ''}`}
                  disabled={currentPage === 1 || isLoading}
                />
              </PaginationItem>
              {[...Array(totalPages)].map((_, i) => (
                <PaginationItem key={i}>
                  <PaginationLink 
                    onClick={() => setCurrentPage(i + 1)}
                    className={`bg-gray-900 border-gray-800 text-gray-100 hover:bg-gray-800 hover:border-purple-500 transition-colors ${currentPage === i + 1 ? 'bg-purple-600 hover:bg-purple-700' : ''}`}
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem>
                <PaginationNext 
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                  className={`bg-gray-900 border-gray-800 text-gray-100 hover:bg-gray-800 hover:border-purple-500 transition-colors ${currentPage === totalPages ? 'pointer-events-none opacity-50' : ''}`}
                  disabled={currentPage === totalPages || isLoading}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default ArtistHome;
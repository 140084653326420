import React, { useState, useRef } from 'react';
import {
  Nav,
  Navbar,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
// import { Offcanvas } from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';

import Footer from './Footer';
import KnktProfileImage from './KnktProfileImage';
import LeftMenu from './LeftMenu';
import NotificationSection from './NotificationSection';
import KnktLogo from './KnktLogo';
import CommunityContainer from '../../containers/CommunityContainer';

import '../../styles/AfterAuth.css';

const AfterAuth = (props) => {
  let oldMenuCollapse = window.localStorage.getItem('menuCollapse');
  if (oldMenuCollapse && oldMenuCollapse === 'true') {
    oldMenuCollapse = true;
  } else {
    oldMenuCollapse = false;
  }

  const [menuCollapse, setMenuCollapse] = useState(oldMenuCollapse);
  const [showPersonal] = useState(false);
  const [menuShow] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const styles = {
    hamburger: {
      marginTop: 10,
    },
    afterAuth: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingLeft: 30,
    },
    afterAuthMenu: {
      overflow: 'hidden',
      height: '100vh',
      backgroundColor: '#000000',
    },
    dashboard: {
      display: 'flex',
      flexDirection: 'row',
     /*  backgroundColor: '#000000', */
      padding: 0,
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflow: 'auto',
      gap: '0.8rem',
      fontSize: '0.8rem',
      overflow: 'hidden',
    },
    menuCenter: {
      textAlign: 'center',
    },
    menuSmall: {
      position: 'absolute',
      top: 50,
      left: 0,
      zIndex: 50,
      backgroundColor: '#000000',
      width: '100%',
      height: '100vh',
    },
    brand: {
      margin: 0,
      textAlign: 'center',
    },
    icon: {
      fontSize: '1.5em',
    },
    navlink: {
      textDecoration: 'none',
      color: 'unset',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 40,
      width: '100%',
    },
    navItem: {
      textAlign: 'center',
      paddingBottom: 20,
    },
    mainContent: {
      height: '100vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%',
    },
    name: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    footer: {
      width: '100%',
      /* backgroundColor: '#000000', */
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuLarge: {
      height: '100vh',
      marginLeft: '0.5rem',
    },
    personal: {
      position: 'absolute',
      bottom: 0,
    },
    personalSmall: {
      position: 'absolute',
      bottom: 50,
    },
    iconText: {
      marginLeft: 15,
    },
    personalContainer: {
      marginLeft: 20,
    },
    personalMenu: {
      padding: '15px 5px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    personalMenuExtra: {
      backgroundColor: '#333333',
      color: '#ffffff',
      border: '1px solid #ffffff',
      borderRadius: 5,
      position: 'absolute',
      bottom: '60px',
    },
    personalName: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingBottom: 10,
    },

    logout: {
      cursor: 'pointer',
    },
  };

  const handleCollapseMenu = (_) => {
    const newCollapse = !menuCollapse;
    setMenuCollapse(newCollapse);

    window.localStorage.setItem('menuCollapse', newCollapse);
  };

  const handleLogout = (event) => {
    event.stopPropagation();
    props.handleLogout();
  };

  // const handleNavBarToggle = show => {
  //   setMenuShow(show);
  //   if (show) {
  //     setTimeout(() => {
  //       setShowPersonal(show);
  //     }, 200);
  //   } else {
  //     setShowPersonal(show);
  //   }
  // };

  const handleToggleNavBar = (_) => {
    setExpanded(!expanded);
  };

  const hideMenu = (_) => {
    setExpanded(false);
  };

  const getUserName = (_) => {
    const { lastName, firstName } = props.user;
    return (firstName || '') + ' ' + (lastName || '');
  };

  const getUserInitials = (_) => {
    const { lastName, firstName, profileImage } = props.user;

    if (profileImage) {
      return (
        <img
          src={profileImage}
          width="50"
          height="50"
          alt="profileImage"
        />
      );
    }

    let name = '';

    if (lastName) {
      name = lastName.substring(0, 1);
    }
    if (name.length > 0) {
      name = firstName.substring(0, 1) + name;
    } else {
      if (firstName.indexOf(' ') > 0) {
        const space = firstName.indexOf(' ');
        name =
          firstName.substring(0, 1) +
          firstName.substring(space + 1, space + 2);
      } else {
        name = firstName.substring(0, 1) + name;
      }
    }

    return name.toUpperCase();
  };

  const renderKnktProfileImage = (
    <KnktProfileImage initials={getUserInitials()} />
  );


  const personalMenu = (
    <div
      style={{ ...styles.personalMenu, ...styles.personalMenuExtra }}
    >
      <div style={styles.personalName}>
        {renderKnktProfileImage}
        <div style={{ marginTop: 10 }}>Hi</div>
        <div>{getUserName()}</div>
      </div>
      <div
        style={{
          margin: '10px 0',
          width: '100%',
          height: 1,
          borderTop: '1px solid #ffffff',
        }}
      />
      <div onClick={handleLogout.bind(this)} style={styles.logout}>
        <i className="fas fa-sign-out-alt" style={styles.icon}></i>
        <span style={styles.iconText}>Log Out</span>
      </div>
    </div>
  );

  return (
    <div
      className="afterAuth"
      style={menuShow ? styles.afterAuthMenu : {}}
    >
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          style={styles.dashboard}
          expanded={expanded}
        >
          <div
            className="knkt-md"
            style={menuShow ? { height: '100vh' } : {}}
          >
            {/*             <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-false`}
              style={styles.hamburger}
              onClick={() => handleToggleNavBar()}
            /> */}
            <div
              aria-controls={`offcanvasNavbar-expand-false`}
              style={styles.hamburger}
              onClick={() => handleToggleNavBar()}
            >
              <KnktLogo
                imgStyle={{ width: 60, marginLeft: '1rem' }}
                divStyle={styles.brand}
              />
            </div>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-false`}
              aria-labelledby={`offcanvasNavbarLabel-expand-false`}
              placement="start"
              style={{ backgroundColor: '#000', width: '100%' }}
            >
              {/*  <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className="mr-auto"
                style={{ ...styles.menu, ...styles.menuSmall }}
              > */}
              {/* <Offcanvas.Body> */}
              <LeftMenu
                menuCollapse={false}
                smallScreen={true}
                user={props.user}
                hideMenu={hideMenu}
                personalMenu={personalMenu}
                handleLogout={handleLogout}
                KnktProfileImage={renderKnktProfileImage}
                resetCommunityHome={props.resetCommunityHome}
              />
              {/*  </Offcanvas.Body> */}
            </Navbar.Offcanvas>
            {/*  </Nav> */}
            {/*  showPersonal && (
                <Nav className="mr-auto" style={{...styles.menu, ...styles.menuCenter, ...styles.personalSmall}}>
                  <Nav.Link style={styles.navItem}>
                    <OverlayTrigger trigger="click" placement="right" overlay={personalMenu}>
                      <NavLink to="#" style={{...styles.navlink}}>
                        <KnktProfileImage initials={getUserInitials()} />
                      </NavLink>
                    </OverlayTrigger>
                  </Nav.Link>
                </Nav>
              ) */}
            {/*   </Navbar.Collapse> */}
          </div>

          <div className="knkt-lg" style={styles.menuLarge}>
            <Navbar id="responsive-navbar-nav">
              <Nav
                className="mr-auto"
                style={{ ...styles.menu, ...styles.menuCenter }}
              >
                <LeftMenu
                  menuCollapse={menuCollapse}
                  user={props.user}
                  personalMenu={personalMenu}
                  handleLogout={handleLogout}
                  KnktProfileImage={renderKnktProfileImage}
                resetCommunityHome={props.resetCommunityHome}
                />
              </Nav>
            </Navbar>

            {/*  <Navbar id="responsive-navbar-nav" style={styles.personal}>
              <Nav className="mr-auto" style={{...styles.menu, ...styles.menuCenter, ...styles.personalSmall}}>
                <Nav style={styles.navItem}>
                  <OverlayTrigger trigger="click" placement="right" overlay={personalMenu}>
                    <NavLink onClick={(event) => event.stopPropagation()} to="#" style={{...styles.navlink}}>
                      <KnktProfileImage initials={getUserInitials()} />
                    </NavLink>
                  </OverlayTrigger>
                </Nav>
              </Nav>
            </Navbar> */}
          </div>
        </Navbar>
      </div>
      <div style={styles.mainContent}>
        <NotificationSection />
        <div style={styles.main}>{props.children}</div>

        <div style={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AfterAuth;

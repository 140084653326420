import { useState, useRef, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { X } from "lucide-react";
import "dayjs/locale/en";

import "./styles.css";

const AddEventDialog = ({ isOpen, onClose, onSave, initialData }) => {
  const [formData, setFormData] = useState({
    eventDate: "",
    venueName: "",
    description: "",
    location: "",
    ticketLink: "",
    isSoldOut: false,
  })
  const [errors, setErrors] = useState({});
  const dialogRef = useRef(null);

  // Initialize form with initialData when provided
  useEffect(() => {
    if (isOpen && initialData) {
      setFormData({
        eventDate: initialData.eventDate.date,
        venueName: initialData.venueName || "",
        description: initialData.description || "",
        location: initialData.location || "",
        ticketLink: initialData.ticketLink || "",
        isSoldOut: initialData.isSoldOut || false,
      });
    } else if (isOpen) {
      setFormData({
        eventDate: "",
        venueName: "",
        description: "",
        location: "",
        ticketLink: "",
        isSoldOut: false,
      });
    }
  }, [isOpen, initialData]);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
      document.body.style.overflow = "hidden";
    } else {
      dialogRef.current?.close();
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const handleClose = () => {
    setFormData({
      eventDate: "",
      venueName: "",
      description: "",
      location: "",
      ticketLink: "",
      isSoldOut: false,
    });
    setErrors({});
    onClose();
  }

  const validateForm = () => {
    const newErrors = {}
    if (!formData.eventDate) newErrors.eventDate = "Event date is required";
    if (!formData.venueName.trim()) newErrors.venueName = "Venue name is required";
    if (!formData.description.trim()) newErrors.description = "Description is required";
    if (!formData.location.trim()) newErrors.location = "Location is required";
    if (!formData.ticketLink.trim()) newErrors.ticketLink = "Ticket link is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleSave = () => {
    if (validateForm()) {
      const eventData = {
        ...formData,
        ticketLink: (formData.ticketLink.startsWith("http") || formData.ticketLink.startsWith("https")) ? formData.ticketLink : `https://${formData.ticketLink}`
      } 

      onSave(eventData);
      setFormData({
        eventDate: "",
        venueName: "",
        description: "",
        location: "",
        ticketLink: "",
        isSoldOut: false,
      });
      handleClose();
    }
  }

  const handleInputChange = (e) => {
    if (e.target) {
      const { name, value, type, checked } = e.target;
      if (errors[name]) setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else {
      const date = e.format("YYYY-MM-DD HH:mm:ss");

      const month = e.format("MMM");
      const day = e.format("DD");
      const year = e.format("YYYY");
      const time = e.format("HH:mm:ss");
      const dateTime = {
        month,
        day,
        year,
        time,
      }
      setFormData((prev) => ({
        ...prev,
        eventDate: {
          date,
          ...dateTime,
        },
      }));
    }
  }

  return (
    <dialog ref={dialogRef} className="add-event-dialog">
      <div className="add-event-dialog-header">
        <h2 className="add-event-dialog-title">{initialData ? "Edit Event" : "Add New Event"}</h2>
        <button onClick={handleClose} className="add-event-dialog-close-button">
          <X className="add-event-dialog-x" />
        </button>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="add-event-form-group">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <div id="date-time-picker-root"></div>
            <label htmlFor="eventDate">Event Date</label>
            <DateTimePicker
              className="add-event-date-time-picker"
              value={formData.eventDate.date}
              onChange={(value) => handleInputChange(value)}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{
                popper: {
                  container: document.getElementById("date-time-picker-root"),
                },
              }}
            />
          </LocalizationProvider>
          {errors.eventDate && <span className="add-event-error">{errors.eventDate}</span>}
        </div>
        <div className="add-event-form-group">
          <label htmlFor="venueName">Venue Name</label>
          <input
            type="text"
            placeholder="Enter venue name"
            id="venueName"
            name="venueName"
            value={formData.venueName}
            onChange={handleInputChange}
          />
          {errors.venueName && <span className="add-event-error">{errors.venueName}</span>}
        </div>
        <div className="add-event-form-group">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            placeholder="Enter description"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
          {errors.description && <span className="add-event-error">{errors.description}</span>}
        </div>
        <div className="add-event-form-group">
          <label htmlFor="location">Location</label>
          <input
            type="text"
            placeholder="Enter location"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
          />
          {errors.location && <span className="add-event-error">{errors.location}</span>}
        </div>
        <div className="add-event-form-group">
          <label htmlFor="ticketLink">Ticket Link</label>
          <input
            type="url"
            id="ticketLink"
            placeholder="Enter ticket link"
            name="ticketLink"
            value={formData.ticketLink}
            onChange={handleInputChange}
          />
          {errors.ticketLink && <span className="add-event-error">{errors.ticketLink}</span>}
        </div>
        <div className="add-event-form-group checkbox">
          <input
            type="checkbox"
            id="isSoldOut"
            name="isSoldOut"
            checked={formData.isSoldOut}
            onChange={handleInputChange}
          />
          <label htmlFor="isSoldOut">Mark the event as sold out</label>
        </div>
        <div className="add-event-button-group">
          <button type="button" onClick={handleClose} className="add-event-cancel-button">
            Cancel
          </button>
          <button type="button" onClick={handleSave} className="add-event-save-button">
            Save
          </button>
        </div>
      </form>
    </dialog>
  )
}

export default AddEventDialog;

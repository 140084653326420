import React from 'react';
import {render} from 'react-dom';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {routerReducer} from 'react-router-redux';
import {BrowserRouter} from 'react-g-analytics';

import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import user from './reducers/user';
import site from './reducers/site';
import token from './reducers/token';
import signUpPage from './reducers/signUpPage';
import social from './reducers/social';
import admin from './reducers/admin';
import calendar from './reducers/calendar';
import history from './redux/manageHistory';

import registerServiceWorker, { unregister } from './registerServiceWorker';
import MainRouter from './components/common/MainRouter';
import {getGoogleAnalytics} from './config/siteConfig';
// import styles from './style/background';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/Index.css';

const store = createStore(
  combineReducers({
    user,
    site,
    token,
    signUpPage,
    social,
    admin,
    calendar,
    router: routerReducer
  }),
  {},
  applyMiddleware(thunk)
);

const styles = {
  index: {
    /* backgroundColor: '#000000', */
    background: "linear-gradient(to bottom right, #030712, #0f172a, #030712)",
    // display: 'flex',
    // height: '100vh',
    // alignItems: 'center',
    // justifyContent: 'center',
    // color: '#000000'
    fontFamily: 'Open Sans, sans-serif',
  },
  videoFullscreenBg: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    zIndex: -100
  },
  videoFullscreenBgVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100vw',
    minHeight: '100vh',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  fixedTop: {
    height: 70,
    backgroundColor: 'transparent',
    zIndex: -1000
  }
};

const router = (
 
  <BrowserRouter id={getGoogleAnalytics()} history={history}>
    <Provider store={store}>
      <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
        <div style={styles.index}>
          {/*<div style={styles.fixedTop}></div>*/}
          <MainRouter />
        </div>
      </MuiThemeProvider>
    </Provider>
  </BrowserRouter>
  
  
);

render(router, document.getElementById('root'));
// registerServiceWorker();
unregister();

// <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
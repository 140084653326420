import { useEffect } from "react";
import axios from "axios";
import { getApiUrl } from '../../config/siteConfig';

const TikTokCallback = (props) => {

    console.log("TikTok Callback: ", props);
    const { history, location } = props;
    const params = new URLSearchParams(location.search);
    let queryParams = {};
    for (let [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    const key = 'code'
    const code =  key in queryParams? queryParams[key] : null;    
    const knktId = props.user.knktId; // Replace with actual user ID

    console.log("TikTok Callback: ", code, knktId);

    useEffect(() => {
        if (code) {
            axios.post(getApiUrl() + '/api/v1/tiktok/token', { code, knktId })
                .then(res => {
                    localStorage.setItem("tikTokAccessToken", res.data.access_token);
                    history.replace(`/connection${location.search}`, { replace: true });
                })
                .catch((error) => {
                    alert("error", error)
                    history.replace(`/connection${location.search}`, { replace: true });
                });
        }
    }, [code]);

    return <div>Processing TikTok login...</div>;
};

export default TikTokCallback;
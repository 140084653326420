import React from "react"



export const Button = React.forwardRef(
    ({ variant = "default", size = "default", children, style, ...props }, ref) => {
        const baseStyles = {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0.375rem",
            fontWeight: 500,
            fontSize: "0.875rem",
            transition: "all 0.2s ease",
            cursor: "pointer",
            outline: "none",
        }

        const variantStyles = {
            default: {
                backgroundColor: "#22d3ee",
                color: "white",
                border: "none",
            },
            outline: {
                backgroundColor: "transparent",
                border: "1px solid #374151",
                color: "#e5e7eb",
            },
            ghost: {
                backgroundColor: "transparent",
                border: "none",
                color: "#e5e7eb",
            },
        }

        const sizeStyles = {
            default: {
                height: "2.5rem",
                padding: "0 1rem",
            },
            sm: {
                height: "2rem",
                padding: "0 0.75rem",
                fontSize: "0.75rem",
            },
            lg: {
                height: "3rem",
                padding: "0 1.5rem",
                fontSize: "1rem",
            },
            icon: {
                height: "2.5rem",
                width: "2.5rem",
                padding: 0,
            },
        }

        const combinedStyles = {
            ...baseStyles,
            ...variantStyles[variant],
            ...sizeStyles[size],
            ...style,
        }

        return (
            <button ref={ref} style={combinedStyles} {...props}>
                {children}
            </button>
        )
    },
)

Button.displayName = "Button"


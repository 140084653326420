import React, { Component, useState } from 'react';
import { Row, Col, Button, Card, Image } from 'react-bootstrap';
import Radium from 'radium';

const CommunityHome = ({onClickCommunityPage}) => {

    const [content, setContent] = useState([]);
    const [isHover, setIsHover] = useState(false)
    const [hoverCard, sethoverCard] = useState('')

    const styles = {
        community: {
            color: '#ffffff',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            minHeight: '94vh',
          },
        socialParent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignContent: 'center',
            flexWrap: 'wrap',
        },
        socialContainer: {
            borderRadius: '1rem',
            height: 217,
            /* background: '#2c2c2c', */
            backgroundColor: "rgba(17, 24, 39, 0.5)",
            backdropFilter: "blur(8px)",
            border: "2px solid #1f2937",
            margin: 15,
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            gap: '3px',
        },
        connectText: {
            fontSize: '16px',
        },
        hover: {
          fb: {
            ':hover': {
              background: '#0B84EE',
              border: 'none',
              cursor: 'pointer',
              boxShadow: '0px 0px 15px 6px #0B84EE',
            },
          },
          yt: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px #FF0000',
              border: 'none',
              background: '#FF0000',
              cursor: 'pointer',
            },
          },
          ig: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px gray',
              border: 'none',
              background:
                'linear-gradient(to right, rgb(236, 47, 75), rgb(0, 159, 255))',
              cursor: 'pointer',
            },
          },
          apple: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px gray',
              border: 'none',
              background:
                'linear-gradient(304deg, rgba(71,71,71,1) 0%, rgba(0,0,0,1) 43%)',
              cursor: 'pointer',
            },
          },
          spotify: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px #1ED760',
              border: 'none',
              background: '#1ED760',
              cursor: 'pointer',
            },
          },
          tiktok: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px #e5004e',
              border: 'none',
              background: '#0000',
              cursor: 'pointer',
            },
          },
          twitter: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px #1A91DA',
              border: 'none',
              background: '#1A91DA',
              cursor: 'pointer',
            },
          },
          eventBrite: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px #F05537',
              border: 'none',
              background: '#F05537',
              cursor: 'pointer',
            },
          },
          soundCloud: {
            ':hover': {
              boxShadow: `0px 0px 15px 6px #FF551A`,
              border: 'none',
              background: '#FF551A',
              cursor: 'pointer',
            },
          },
          deezer: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px gray',
              border: 'none',
              background: '#000',
              cursor: 'pointer',
            },
          },
        },
    }
    const accountType = ['artists','fans','event organizers','labels','agents','media','venues','publishers','artist managers'];
    
    const hoverKeys = Object.keys(styles.hover)
    const capitalizeWords = (str) => {
      return str.split(' ')
                .map(word => word[0].toUpperCase() + word.slice(1))
                .join(' ')
    }
    const communityPages = accountType.map((type,index) => {
    const typeCapitalized = capitalizeWords(type)
    const hoverKey = hoverKeys[index % hoverKeys.length]
      return {
        id: type,
        key: type,
        value: typeCapitalized,
        hover: hoverKey,
      };
    });


    

    const onHoverIn = (e) => {
        // console.log(e.target.id, "mouse enter");
        setIsHover(true)
        sethoverCard(e.target.id)
    };

    const onHoverOut = (e) => {
        // console.log(e.target.id, "mouse exit");
        setIsHover(false)
        sethoverCard('')
    };

    

    return (
        <>
        <div style={{...{ margin: '1rem' }, ...styles.community}}>
            
            
                <Row nogutters="true" style={styles.socialParent}>
                {
                    communityPages.map((page) => (
                        
                        <Col xs={6} sm={4} md={3}>
                            <div
                            id={page.id}
                            key={page.key}
                            value={page.value}
                            style={[styles.socialContainer, styles.hover[page.hover]]}
                            onMouseEnter={onHoverIn}
                            onMouseLeave={onHoverOut}
                            onClick = {() => {onClickCommunityPage(page.value)}}
                            >
                                <i class="fab fa-tikok fa-3x brand-opacity"></i>
                                <h4 className="text-uppercase">{page.value}</h4>
                            </div>
                        </Col>
                    )
                )}
            </Row>
            
            
        </div>
        </>
        
    );
};

export default Radium(CommunityHome);